.modal-close-contract{
  display: flex;
  flex-direction: column;
  padding:$padding-red $padding-red $padding-red*2 $padding-red;
  width:464px;
  min-height: 200px;
  @include background-opacity($ts2, 0.8);
  border:1px solid;
  @include border-opacity($ts3, 0.3);
  border-radius: 4px;
  @extend %align-horizontal;
  >.title{
    display: flex;
    margin-bottom: 28px;
    >img{
      width:24px;
      object-fit:fill;
      margin-right:$margin-pink;
    }
    >p{
      @extend %font-l;
      color:$ts10;
    }
  }
  >.single-item{
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    >p{
      @extend %font-s-bold;
      color:$ts10;
      margin-bottom: $margin-green;
    }
    >div{
      height: 32px;
      @include background-opacity($ts20, 0.2);
      border-radius: 4px;
      padding:0px 16px;
      >p{
        @extend %font-xs-bold;
        @include color-opacity($ts10, 0.5);
        line-height: 32px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  >.multiple-item{
    display:flex;
    flex-direction: column;
    margin-bottom: 16px;
    >p{
      @extend %font-s-bold;
      color:$ts10;
      margin-bottom: $margin-green;
    }
    >div{
      padding:16px;
      @include background-opacity($ts20, 0.2);
      border-radius: 4px;
      >p{
        @extend %font-xs;
        color:$ts10;
        &:nth-child(odd){
          margin-bottom:8px
        }
        &:nth-child(even){
          margin-bottom:16px
        }
        &:last-child{
          margin-bottom: 0px;
        }
      }
    }
  }
  >.check-term{
    display:flex;
    margin-bottom: 24px;
    >input[type="checkbox"]{
      margin-right: 6px;
      cursor: pointer;
    }
    >label{
      @extend %font-xs;
      color:$ts10;
      cursor: pointer;
    }
  }
  >.button-action{
    >button{
      @extend %align-horizontal;
    }
  }
}

@media screen and (max-width: $breakpoint-width-xs){
  .modal-close-contract{
    width:100%;
    transform: translate(-50%, 0%);
    top:0px;
  }
}
