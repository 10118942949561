.box-financial-comission {
  >.commission-overview{
    display: flex;
    flex-direction: column;
    padding:24px;
    background:$ts1-2;
    border-radius: 8px;
    >.commission-product{
      display: flex;
      flex-direction: column;
      width:100%;
      margin-bottom: 24px;
      >.total-bar{
        display:flex;
        align-items: center;
        background:$ts1;
        border:1px solid $ts1-3;
        padding:8px;
        border-radius: 8px;
        margin-bottom: 4px;
        >img{
          height: 32px;
          width: 32px;
          margin-right: 12px;
        }
        >p{
          @extend %font-m;
          color:$ts10;
        }
        >div{
          display:flex;
          margin-left: auto;
          padding:6px 12px;
          height: 100%;
          border-radius: 8px;
          border:1px solid $success;
          align-items: center;
          >p:nth-child(1){
            @extend %font-xxs-bold;
            color:$ts3;
            margin-right: 12px;
          }
          >p:nth-child(2){
            @extend %font-s-bold;
            color:$ts10;
          }
        }
      }
      >.referral-bar{
        display: flex;
        justify-content: space-between;
        >div{
          display:flex;
          flex-direction: column;
          padding:8px 16px;
          border-radius: 8px;
          background:$ts1;
          align-items: center;
          >p{
            text-align: center;
          }
          >p:nth-child(1){
            @extend %font-xxs-bold;
            color:$ts3;
          }
          >p:nth-child(2){
            @extend %font-s;
            color:$ts10;
          }
        }

      }
      &:last-child{
        margin-bottom: 0px;
      }
    }
  }
  >.table{
    >.options{
      >.right-side{
        >.select{
          margin-right: 16px;
        }
      }
    }
    >.table-container{
      overflow-x: auto;
      >table{
        width: 100%;
        color:$ts10;
        >thead{
          text-align: left;
          @extend %font-xxs-bold;
          color:$ts3;
          border-bottom: 1px solid $ts1-3;
          >tr{
            >th{
              cursor:pointer;
              padding-bottom: 8px;
              >img{
                margin-top: -2px;
              }
            }
          }
        }
        >tbody{
          >tr{
            border-bottom: 1px solid $ts2;
            &:last-child{
              border-bottom: 0px;
            }
            >td{
              padding:8px 0px;
              color:$ts10;
              &:nth-of-type(1){
                >p{
                  @extend %font-xxs;
                }
              }
              &:nth-of-type(2){
                >img{
                  height: 16px;
                  width: 16px;
                }
              }
              &:nth-of-type(3){
                >p{
                  @extend %font-s;
                  color:$ts10;
                }
              }
              &:nth-of-type(4){
                >p{
                  @extend %font-s;
                  color:$ts10;
                }
              }
              &:nth-of-type(5){
                >p{
                  @extend %font-s;
                  color:$ts10;
                  &.success{
                    color: $success;
                  }
                  &.warning{
                    color:$warning;
                  }
                  &.danger{
                    color:$danger;
                  }
                }
              }
              &:nth-of-type(n+6):nth-of-type(-n+9){
                >p{
                  @extend %font-s;
                  color:$ts10;
                }
              }
            }
          }
        }
      }
    }
  }
}

.box-financial-payments {
  display: flex;
  flex-direction: column;

  .information-network {
    width: 100%;
    height: 56px;
    position: relative;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    background: $ts1;

    > div:nth-child(1) {
      display: flex;

      .status {
        height: 100%;
        width: 88px;
        background: $success;

        > div {
          @extend %align-vertical;

          p {
            @extend %font-s-bold;
            text-transform: uppercase;
            color: $ts10;
            text-align: center;
            margin-bottom: 2px;

            &:last-child {
              @include color-opacity($ts1, 0.6);
              margin-bottom: 0px;
            }
          }
        }
      }

      .item {
        color: $ts10;

        > div {
          margin-left: 20px;
          @extend %align-vertical;

          > p:nth-child(1) {
            @extend %font-xxs-bold;
            margin-bottom: 4px;
          }

          > p:nth-child(2) {
            @extend %font-xxs;

            &.spotlight {
              color: $success;
            }
          }
        }
      }
    }

    > div:nth-child(2) {
      display: flex;

      > div {
        > button {
          margin-right: 20px;
          @extend %align-vertical;
        }
      }
    }
  }

  .history-payments {
    height: auto;
    width: 100%;

    > div:nth-child(1) {
      background: $ts1;
      padding: 12px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $ts2;

      .title {
        p {
          @extend %font-l;
          color: $ts10;
        }
      }

      .filter {
        display: flex;

        > div:nth-child(1) {
          width: 152px;
          margin-right: 12px;
        }

        > div:nth-child(2) {
          display: flex;
          margin-right: 8px;

          > p {
            color: $ts10;
            @extend %font-s;
            line-height: 27px;
            margin-right: 4px;
          }

          input {
            &::-webkit-calendar-picker-indicator {
              &:hover {
                background-color: transparent;
                cursor: pointer;
              }
            }

            &::-webkit-clear-button {
              display: none;
            }

            &::-webkit-inner-spin-button {
              display: none;
            }
          }
        }

        > div:nth-child(3) {
          display: flex;

          > p {
            color: $ts10;
            @extend %font-s;
            line-height: 27px;
            margin-right: 4px;
          }

          input {
            &::-webkit-calendar-picker-indicator {
              &:hover {
                background-color: transparent;
                cursor: pointer;
              }
            }

            &::-webkit-clear-button {
              display: none;
            }

            &::-webkit-inner-spin-button {
              display: none;
            }
          }
        }
      }
    }

    > div:nth-child(2) {
      > table {
        background: $ts1;

        tr {
          border-bottom: 1px solid $ts2;
          width: 100%;

          th {
            text-align: left;
            color: $ts3;
            @extend %font-s-bold;
            padding: 8px 0px;

            &:nth-child(1) {
              width: 104px;
              padding-left: 12px;
            }

            &:nth-child(2) {
              width: 140px;
            }

            &:nth-child(3) {
              width: 120px;
            }

            &:nth-child(4) {
              width: 108px;
            }

            &:nth-child(5) {
              width: 152px;
            }

            &:nth-child(6) {
              width: 208px;
            }

            &:nth-child(7) {
              width: 82px;
            }

            &:nth-child(8) {
              width: 102px;
            }
          }

          td {
            text-align: left;
            color: $ts10;
            @extend %font-xs;
            padding: 12px 6px 12px 0px;

            &:nth-child(1) {
              padding-left: 12px;
            }
            &:nth-child(6) {
              > a {
                @extend %font-link;
              }
            }

            span {
              &.confirmed {
                color: $success;
              }

              &.waiting {
                color: $info;
              }
            }

            &.adress {
              color: $primary;
              cursor: pointer;

              > img {
                margin-left: 5px;
              }
            }
          }
        }
      }
    }
  }
}

.box-financial-extract {
  .history-extract {
    height: auto;
    width: 100%;

    > div:nth-child(1) {
      background: $ts1;
      padding: 12px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $ts2;

      .title {
        p {
          @extend %font-l;
          color: $ts10;
        }
      }

      .filter {
        display: flex;

        > div:nth-child(1) {
          width: 152px;
          margin-right: 12px;
        }

        > div:nth-child(2) {
          display: flex;
          margin-right: 8px;

          > p {
            color: $ts10;
            @extend %font-s;
            line-height: 27px;
            margin-right: 4px;
          }

          input {
            &::-webkit-calendar-picker-indicator {
              &:hover {
                background-color: transparent;
                cursor: pointer;
              }
            }

            &::-webkit-clear-button {
              display: none;
            }

            &::-webkit-inner-spin-button {
              display: none;
            }
          }
        }

        > div:nth-child(3) {
          display: flex;

          > p {
            color: $ts10;
            @extend %font-s;
            line-height: 27px;
            margin-right: 4px;
          }

          input {
            &::-webkit-calendar-picker-indicator {
              &:hover {
                background-color: transparent;
                cursor: pointer;
              }
            }

            &::-webkit-clear-button {
              display: none;
            }

            &::-webkit-inner-spin-button {
              display: none;
            }
          }
        }
      }
    }

    > div:nth-child(2) {
      > table {
        background: $ts1;

        tr {
          border-bottom: 1px solid $ts2;
          width: 100%;

          th {
            text-align: left;
            color: $ts3;
            @extend %font-s-bold;
            padding: 8px 0px;

            &:nth-child(1) {
              width: 172px;
              padding-left: 12px;
            }

            &:nth-child(2) {
              width: 140px;
            }

            &:nth-child(3) {
              width: 380px;
            }

            &:nth-child(4) {
              width: 188px;
            }

            &:nth-child(5) {
              width: 136px;
            }
          }

          td {
            text-align: left;
            color: $ts10;
            @extend %font-xs;
            padding: 12px 0px;

            &:nth-child(1) {
              padding-left: 12px;
            }

            &.success {
              color: $success;
            }

            &.info {
              color: $info;
            }

            &.adress {
              color: $primary;
              cursor: pointer;

              > img {
                margin-left: 5px;
              }
            }
          }
        }
      }
    }
  }
}

.box-financial-debits{
  .title{
    margin-bottom: $margin-blue;
    p{
      color:$ts10;
      &:nth-child(1){
        @extend %font-l;
        line-height: 30px;
        margin-bottom: $margin-red;
      }
      &:nth-child(2){
        @extend %font-xs;
      }
    }
  }
  .debits-bar{
    margin-bottom: $margin-blue;
    .bar{
      display: flex;
      padding:$padding-orange;
      @include background-opacity($ts1, 0.5);
      margin-bottom: 1px;
      .info-product{
        display: flex;
        align-items: center;
        > input[type='checkbox']{
          width: 16px;
          height: 16px;
          background: transparent;
          margin-right: $margin-orange;
        }
        > div:nth-child(2){
          height: 36px;
          width: 36px;
          background: $ts10;
          border-radius:50%;
          margin-right: $margin-pink;
          > img{
            width: 36px;
          }
        }
        > p{
          @extend %font-m;
          color:$ts10;
          width: 220px;
        }
      }
      .info-debit{
        display: flex;
        position: relative;
        width: 100%;
        > div{
          display: flex;
          flex-direction: column;
          p{
            @extend %font-xs;
            color:$ts10;
            line-height: 18px;
            &.danger{
              color:$danger;
            }
            &:first-child{
              font-weight: bold;
            }
          }
          &:nth-child(1){
            width: 98px;
          }
          &:nth-child(2){
            width: 220px;
          }
          &:nth-child(3){
            width: 98px;
          }
          &:nth-child(4){
            width: 180px;
          }
          &:nth-child(5){
            width: 98px;
          }
        }
      }
    }
  }
  .payment-bar{
    display: flex;
    justify-content: space-between;
    @include background-opacity($ts1, 0.8);
    > div:nth-child(1){
      display: flex;
      > div{
        width: 160px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding:$padding-blue 0px;
        border-right: 1px solid $ts1;
        p{
          color:$ts10;
          text-align: center;
          &:nth-child(1){
            @extend %font-s;
            font-weight: bold;
            color:$ts3;
          }
          &:nth-child(2){
            @extend %font-m;
          }
        }
      }
    }
    > div:nth-child(2){
      padding:$padding-red;
      display: flex;
      align-items: center;
      button{
        padding:0px 30px;
      }
    }
  }
}

@include screenMaxWidth(1060px){
  .box-financial-comission{
    >.commission-overview{
      >.commission-product{
        >.referral-bar{
          flex-wrap: wrap;
          justify-content: space-around;
          >div{
            margin-bottom: 8px;
          }
        }
      }
    }
    >.table{
      >.table-container{
        >table{
          min-width:854px;
        }
      }
    }
  }
}

@include screenMaxWidth(500px){
  .box-financial-comission{
    >.commission-overview{
      >.commission-product{
        >.referral-bar{
          >div{
            width:100%;
          }
        }
      }
    }
  }
}


//Media comissions e financial debits
@media screen and (max-width: $breakpoint-width-xl) {
  .box-financial-comission {
    .comission-summary {
      .detail-comission {
        width: 100%;
        margin-bottom: 12px;
        overflow-x: auto;
        overflow-y: none;

        > div {
          width: 800px;
        }

        > div:nth-child(1) {
          > div:first-child {
            min-width: 108px;
          }

          > div:nth-child(n + 2):nth-child(-n + 8) {
            min-width: 92px;
          }

          > div:last-child {
            min-width: 110px;
            position: relative;
          }
        }

        > div:nth-child(2) {
          > div:first-child {
            min-width: 108px;
          }

          > div:nth-child(n + 2):nth-child(-n + 8) {
            min-width: 92px;
          }

          > div:last-child {
            min-width: 110px;
            position: relative;
          }
        }

        > div:nth-child(3) {
          > div:first-child {
            min-width: 108px;
          }

          > div:nth-child(n + 2):nth-child(-n + 8) {
            min-width: 92px;
          }

          > div:last-child {
            min-width: 110px;
            position: relative;
          }
        }
      }
    }
  }
  .box-financial-debits{
    .debits-bar{
      .bar{
        .info-product{
          > p{
            width: auto;
            margin-right: $margin-red;
          }
        }
         .info-debit{
            > div{
              margin-right: $margin-red;
              &:nth-child(1){
                width: auto;
              }
              &:nth-child(2){
                width: auto;
              }
              &:nth-child(3){
                width: auto;
              }
              &:nth-child(4){
                width: auto;
              }
              &:nth-child(5){
                width: auto;
              }
            }
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-width-l) {
  .box-financial-comission {
    .comission-summary {
      .total-comission {
        min-width: 150px;
        height: 100px;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-width-m) {
  .box-financial-comission {
    .comission-summary {
      .total-comission {
        display: none;
      }
    }

    .comission-history {
      height: auto;
      width: 100%;
      overflow-x: auto;

      > div {
        min-width: 780px;
      }
    }
  }

  .box-financial-debits{
    .debits-bar{
      .bar{
        flex-direction: column;
        padding:0px;
        .info-product{
          padding:$padding-red;
          justify-content: center;
        }
        .info-debit{
          background: $ts1;
          padding:$padding-red;
        }
      }
    }
    .payment-bar{
      flex-direction: column;
      > div:nth-child(1){
        justify-content: center;
        border-bottom: 1px solid $ts1;
      }
      > div:nth-child(2){
        display: flex;
        justify-content: center;
      }
    }
  }

}

@media screen and (max-width: $breakpoint-width-xs){
  .box-financial-debits{
    .debits-bar{
      .bar{
        .info-debit{
          justify-content: center;
          > div:nth-child(2){
            display: none;
          }
          > div:nth-child(4){
            display: none;
          }
        }
      }
    }
    .payment-bar{
      > div:nth-child(1){
        > div:nth-child(1){
          display: none;
        }
      }
    }
  }
}
//Media payments
@media screen and (max-width: $breakpoint-width-xl) {
  .box-financial-payments {
    .information-network {
      > div:nth-child(1) {
        > .item:nth-child(2) {
          display: none;
        }

        > .item:nth-child(3) {
          display: none;
        }

        > .item:nth-child(4) {
          display: none;
        }

        > .item:nth-child(6) {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-width-m) {
  .box-financial-payments {
    .information-network {
      height: auto;
      flex-direction: column;

      > div:nth-child(1) {
        height: 60px;
        border-bottom: 2px solid $ts2;

        > .item:nth-child(3) {
          display: block;
        }

        > .item:nth-child(6) {
          display: block;
        }
      }

      > div:nth-child(2) {
        height: 60px;

        > div {
          position: relative;
          left: 50%;
          transform: translateX(-50%);

          > button:last-child {
            margin-right: 0px;
          }
        }
      }
    }

    .history-payments {
      overflow-x: auto;

      > div {
        min-width: 800px;
      }
    }
  }

}

@media screen and (max-width: $breakpoint-width-xs) {
  .box-financial-payments {
    .information-network {
      > div:nth-child(1) {
        > .item:nth-child(3) {
          display: none;
        }

        .status {
          width: 40%;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-width-xxs) {
  .box-financial-payments {
    .information-network {
      > div:nth-child(1) {
        > .item:nth-child(6) {
          display: none;
        }
      }

      > div:nth-child(2) {
        height: auto;
        padding: 10px 0px;

        > div {
          display: flex;
          flex-direction: column;

          > button {
            top: 0;
            transform: translate(-50%, 0);
            width: 170px;
            left: 50%;

            &:first-child {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}

//Media extract
@media screen and (max-width: $breakpoint-width-m) {
  .box-financial-extract {
    .history-extract {
      overflow-x: auto;

      > div {
        min-width: 800px;
      }
    }
  }
}
