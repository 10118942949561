// circle menu tooltip
.badge-menu {
  height: 18px;
  width: 18px;
  border-radius: 9px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  font-weight: 300;
}

.badge-success {
  background: $success;
  color: $ts10;
}

.badge-danger {
  background: $danger;
  color: $ts10;
}

.badge-info {
  background: $info;
  color: $ts10;
}

.badge-primary {
  background: $primary;
  color: $ts10;
}
