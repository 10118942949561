//modal choose plan
.box-choose-plan {
  width: 472px;
  border-radius: 4px;
  border: 1px solid;
  @include border-opacity($ts3, 0.1);
  @extend %align-center;
  .content {
    @include background-opacity($ts2, 0.6);
    padding: 20px;
    display: flex;
    flex-direction: column;
    > div:nth-of-type(1) {
      margin-bottom: 20px;
      p {
        @extend %font-l;
        color: $ts10;
        text-align: center;
      }
    }
    > div:nth-of-type(2) {
      @include background-opacity($ts1, 0.5);
      padding: 8px;
      margin-bottom: 8px;
      img {
        @extend %align-horizontal;
      }
    }
    > div:nth-of-type(3) {
      display: flex;
      .plan-description {
        width: 50%;
        padding: 20px;
        @include background-opacity($ts1, 0.5);
        display: flex;
        flex-direction: column;
        &:first-child {
          margin-right: 8px;
        }
        > div:nth-of-type(1) {
          display: flex;
          margin-bottom: 8px;
          img {
            height: 42px;
            width: 42px;
            margin-right: 10px;
          }
          div {
            p:nth-of-type(1) {
              @extend %font-m;
              color: $ts10;
              margin-bottom: 4px;
            }
            p:not(:nth-of-type(1)) {
              color: $ts3;
              @extend %font-xxs;
            }
          }
        }
        > div:nth-of-type(2) {
          @include background-opacity($ts1, 0.8);
          padding: 12px 0px;
          margin-bottom: 12px;
          p {
            text-align: center;
          }
          p:nth-of-type(odd) {
            @extend %font-xs;
            color: $ts3;
            margin-bottom: 4px;
          }
          p:nth-of-type(even) {
            @extend %font-m-bold;
            color: $ts10;
            margin-bottom: 8px;
            &:last-child {
              margin-bottom: 0px;
            }
          }
        }
        > div:nth-of-type(3) {
          margin-bottom: 12px;
          p {
            @include color-opacity($ts10, 0.9);
            @extend %font-xs;
            line-height: 15px;
            &:first-child {
              @include color-opacity($ts10, 0.9);
              @extend %font-s-bold;
              margin-bottom: 8px;
            }
          }
        }
      }
    }
  }
}

//media choose plan
@media screen and (max-width: $breakpoint-width-m) {
  .box-choose-plan {
    top: 0%;
    transform: translate(-50%, 0%);
  }
}
@media screen and (max-width: $breakpoint-width-xs) {
  .box-choose-plan {
    width: 100%;
    .content {
      padding: 15px;
      > div:nth-of-type(3) {
        flex-direction: column;
        .plan-description {
          width: 100%;
          margin-bottom: 20px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
@media screen and (max-width: $breakpoint-width-xxs) {
  .box-choose-plan {
    .content {
      > div:nth-of-type(2) {
        img {
          width: 100%;
        }
      }
    }
  }
}
