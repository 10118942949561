.box-payment,
.box-payment-initial {
  width: 472px;
  border-radius: 4px;
  @extend %align-center;

  .menu {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    @include background-opacity($ts1, 0.8);

    > div:nth-child(1) {
      > p {
        @extend %font-l;
        color: $ts10;
      }
    }
  }

  .content {
    @include background-opacity($ts2, 0.6);
    padding: 20px;

    .info-payment {
      display: flex;
      margin-bottom: 20px;

      > div:nth-child(1) {
        width: 252px;
        display: flex;
        flex-direction: column;
        position: relative;
        margin-right: 20px;

        > div:nth-child(1) {
          display: flex;
          justify-content: space-between;
          margin-bottom: 24px;

          > p:nth-child(1) {
            color: $ts3;
            @extend %font-s;
          }

          > p:nth-child(2) {
            color: $ts10;
            @extend %font-s;
          }
        }

        > div:nth-child(2) {
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;

          .font-small {
            color: $ts10;
            @extend %font-s;
            margin-bottom: 8px;
            text-align: center;
          }

          .font-large {
            color: $ts10;
            @extend %font-xl-bold;
            text-align: center;
          }

          .font-prefix {
            color: $ts10;
            @extend %font-l-bold;
            text-align: center;
          }

          .font-line-through {
            text-decoration: line-through;
          }
        }

        > div:nth-child(3) {
          display: flex;
          flex-direction: column;

          > p {
            color: $ts3;
            @extend %font-xs;
            margin-bottom: 2px;
          }

          > div {
            > input {
              @extend %font-xs;
              cursor: pointer;
            }

            > button {
              width: 100%;
            }
          }
        }

        // TODO: LUCAS
        > div:nth-child(4) {
          visibility: collapse;
          position: absolute;
          bottom: 40px;
          right: 4px;
          background: $ts2;
          padding: 4px 10px;
          border-radius: 1px;
          border: 1px solid $success;
          transition: 0.5s;
          opacity: 0;

          p {
            @extend %font-xxs;
            color: $ts10;
          }
        }

        // TODO END
      }

      > div:nth-child(2) {
        width: 160px;
        @include background-opacity($ts1, 0.5);

        > img,
        > svg {
          @extend %align-center;
        }
      }

      // TODO: LUCAS
      &.showing-copy-tooltip {
        > div:nth-child(1) {
          > div:nth-child(4) {
            opacity: 1;
            visibility: visible;
          }
        }
      }

      // TODO END
    }

    .info-product {
      > div:nth-child(1) {
        margin-bottom: 8px;

        > p {
          @extend %font-xs;
          color: $ts10;
        }
      }

      > div:nth-child(2) {
        width: 100%;
        margin-bottom: 20px;
        @include background-opacity($ts1, 0.5);
        border-radius: 4px;
        padding: 20px 0px;

        > p {
          @extend %font-l;
          text-align: center;
          color: $ts10;
          small {
            @extend %font-s;
          }
        }

        > p:nth-child(1) {
          @extend %font-s;
          margin-bottom: 8px;
        }
      }

      > div:nth-child(3) {
        display: flex;
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid;
        @include border-opacity($ts3, 0.2);

        > img {
          height: 16px;
          margin-right: 8px;
        }

        > p {
          @extend %font-xxs;
          color: $ts10;
        }
      }

      > button {
        min-width: 116px;
        @extend %align-horizontal;
      }
    }
  }
}

.box-payment-processing {
  width: 472px;
  border-radius: 4px;
  border: 1px solid;
  @include border-opacity($ts3, 0.1);
  @extend %align-center;

  .menu {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    @include background-opacity($ts1, 0.8);

    > div:nth-child(1) {
      > p {
        @extend %font-l;
        color: $ts10;
      }
    }
  }

  .content {
    @include background-opacity($ts2, 0.6);
    padding: 20px;

    .info-product {
      > div:nth-child(1) {
        margin-bottom: 8px;

        > p {
          @extend %font-xs;
          color: $ts10;
        }
      }

      > div:nth-child(2) {
        width: 100%;
        margin-bottom: 20px;
        @include background-opacity($ts1, 0.5);
        border-radius: 4px;
        padding: 20px 0px;

        > p:nth-child(1) {
          @extend %font-s;
          text-align: center;
          color: $ts10;
          margin-bottom: 8px;
        }

        > p:nth-child(2) {
          @extend %font-l;
          text-align: center;
          color: $ts10;
        }
      }
    }

    .info-transaction {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid;
      @include border-opacity($ts3, 0.2);
      padding-bottom: 20px;
      margin-bottom: 20px;

      //title status transaction
      > div:nth-child(1) {
        margin-bottom: 8px;

        > p {
          @extend %font-xs;
          color: $ts10;
        }
      }

      //detail status transaction
      > div:nth-child(2) {
        display: flex;
        flex-direction: column;
        @include background-opacity($ts1, 0.5);
        padding: 8px;
        border-radius: 2px;

        > div:nth-child(1) {
          display: flex;

          > div {
            p {
              @extend %font-xxs;
              color: $ts3;
            }
          }

          > div:nth-child(1) {
            width: 80px;
          }

          > div:nth-child(2) {
            width: 252px;
          }

          > div:nth-child(3) {
            width: 82px;
          }
        }

        > div:nth-child(2) {
          display: flex;

          > div {
            p {
              @extend %font-xxs;
              color: $ts10;
            }
          }

          > div:nth-child(1) {
            width: 80px;
            padding-top: 8px;

            > p {
              line-height: inherit;
            }
          }

          > div:nth-child(2) {
            width: 252px;
            padding-top: 12px;

            > a {
              @extend %font-link;
              @extend %font-xxs;
              line-height: 18px;
            }
          }

          > div:nth-child(3) {
            width: 82px;
            padding-top: 12px;
            overflow-x: scroll;
            > p {
              line-height: 18px;
            }
            &::-webkit-scrollbar {
              width: 0px;
            }
          }
        }
      }
    }

    > button {
      @extend %align-horizontal;
      width: 116px;
    }
  }
}

.box-payment-finished {
  width: 472px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 4px;
  border: 1px solid;
  @include border-opacity($ts3, 0.1);
  @include background-opacity($ts2, 0.6);
  @extend %align-center;

  > div:nth-child(1) {
    margin-bottom: 20px;

    p {
      @extend %font-l;
      color: $ts10;
      text-align: center;
    }
  }

  > div:nth-child(2) {
    text-align: center;
    margin-bottom: 4px;
    padding: 12px 0px;
    @include background-opacity($ts1, 0.5);

    p {
      @extend %font-xs;
      color: $ts10;
      margin-bottom: 4px;
    }

    a {
      @extend %font-xxs;
      @extend %font-link;
    }
  }

  > div:nth-child(3) {
    display: flex;
    position: relative;
    margin-bottom: 20px;
    padding: 12px;
    @include background-opacity($ts1, 0.5);

    > img:nth-child(1) {
      margin-right: 12px;
    }

    > div:nth-child(2) {
      display: flex;
      flex-direction: column;

      > p:nth-child(1) {
        @extend %font-s;
        color: $ts10;
        margin-bottom: 8px;
      }

      > p:nth-child(2) {
        @extend %font-l;
        color: $ts10;
      }
    }

    > button {
      right: 12px;
      position: absolute;
      @extend %align-vertical;
    }
  }

  > div:nth-child(4) {
    margin-bottom: 20px;

    > p:nth-child(1) {
      @extend %font-xs-bold;
      color: $ts10;
      margin-bottom: 4px;
    }

    > p:nth-child(2) {
      @extend %font-xxs;
      color: $ts10;
      line-height: 16px;
    }
  }

  > div:nth-child(5) {
    display: flex;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid;
    @include border-opacity($ts3, 0.2);

    > div:nth-child(1) {
      width: 104px;
      height: 72px;
      background: $ts3;
      margin-right: 12px;

      > img {
        @extend %align-center;
      }
    }

    > div:nth-child(2) {
      > div:nth-child(1) {
        margin-bottom: 14px;

        > p {
          @extend %font-xs;
          color: $ts10;

          > a {
            @extend %font-link;
          }

          &:first-child {
            margin-bottom: 4px;
          }
        }
      }
    }
  }

  > button {
    width: 120px;
    @extend %align-horizontal;
  }
}

//media modal payment
@media screen and (max-width: $breakpoint-width-m) {
  .overlay {
    padding: 20px;
  }

  .box-payment-initial,
  .box-payment,
  .box-payment-processing,
  .box-payment-finished {
    top: 0%;
    transform: translate(-50%, 0%);
    width: 100%;
    margin-bottom: 10px;

    .info-payment {
      > div:nth-child(1) {
        min-width: 252px;
      }

      > div:nth-child(2) {
        width: 100% !important;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-width-s) {
  .overlay {
    padding: 10px;
  }

  .box-payment-initial,
  .box-payment,
  .box-payment-processing {
    top: 0%;
    transform: translate(-50%, 0%);
    width: 100%;

    .info-payment {
      > div:nth-child(1) {
        min-width: 0px;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-width-xs) {
  .box-payment-initial,
  .box-payment {
    .content {
      padding: 10px;

      .info-payment {
        flex-direction: column-reverse;

        > div:nth-child(1) {
          width: 100%;
        }

        > div:nth-child(2) {
          width: 100%;
          height: 150px;
          margin-bottom: 20px;
        }
      }
    }
  }

  .box-payment-processing {
    .content {
      .info-transaction {
        width: 100%;
        overflow-x: scroll;

        > div:nth-child(2) {
          min-width: 440px;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-width-xxs) {
  .box-payment-initial,
  .box-payment {
    .menu {
      > div:nth-child(2) {
        display: none;
      }
    }

    .content {
      .info-payment {
        min-width: 0px;
      }
    }
  }

  .box-payment-processing {
    .menu {
      > div:nth-child(2) {
        display: none;
      }
    }
  }

  .box-payment-finished {
    > div:nth-of-type(3) {
      flex-direction: column;

      > img {
        display: none;
      }
    }

    > div:nth-of-type(5) {
      > div:nth-child(1) {
        min-width: 104px;
        height: 100%;
      }

      > div:nth-child(2) {
        button {
          height: auto;
        }
      }
    }
  }
}
