.box-signup {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  > div:nth-child(1) {
    display: flex;
    flex-direction: column;
    padding:30px;
    border-radius: 16px;
    width: 100%;
    max-width: 572px;
    height: auto;
    background: $ts10;
    > div:nth-child(1) {
        margin-bottom: 40px;
        >img{
          width:100%;
          max-width: 250px;
        }
    }
    > div:nth-child(2) {
        display: flex;
        flex-direction: column;
        > div:nth-child(1) {
          margin-bottom: 40px;
          > p {
            @extend %font-l;
            color: $ts1;
          }
        }
        > div:nth-child(n + 2):nth-child(-n + 10) {
          display: flex;
          margin-bottom: 20px;
          > p:nth-child(1) {
            @extend %font-m;
            color: $ts3;
            width: 168px;
            line-height: 28px;
          }
          > div:nth-child(2) {
            flex-grow: 1;
            > input,
            select {
              color: $ts3;
              border-radius: 2px;
              &::placeholder {
                color: $ts3;
              }
            }
            &.i-disable {
              > input {
                color: $ts10;
              }
            }
          }
        }
        > div:nth-child(11) {
          display: flex;
          margin-bottom: 40px;
          > p:nth-child(1) {
            @extend %font-m;
            color: $ts3;
            width: 168px;
            line-height: 28px;
          }
          > div:nth-child(2) {
            flex-grow: 1;
            display: flex;
            > input,
            select {
              color: $ts3;
              border-radius: 2px;
              &::placeholder {
                color: $ts3;
              }
            }
            > select:nth-child(1) {
              width: 64px;
              margin-right: 12px;
            }
            > select:nth-child(2) {
              width: 172px;
              margin-right: 12px;
            }
            > select:nth-child(3) {
              width: 84px;
            }
          }
        }
        > button:nth-child(12) {
          width: 234px;
          @extend %align-horizontal;
        }
        > div:nth-child(13) {
          margin-bottom: 20px;
        }
        > div:nth-child(14) {
          display: flex;
          > img {
            width: 16px;
            height: 16px;
            margin-right: 4px;
          }
          > div {
            > p {
              @extend %font-xs;
              color: $ts1;
            }
          }
        }
    }
  }
}



@media screen and (max-height: 830px) {
  .box-signup{
    align-items:initial;
    padding:20px;
    >div:nth-child(1){
      overflow-y:auto;
    }
  }
}
@media screen and (max-width: 600px) {
  .box-signup{
    >div:nth-child(1){
      >div:nth-child(2){
        >.box-input{
          flex-direction: column;
        }
      }
    }
  }
}
