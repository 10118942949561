//modal affiliate overview
.box-affiliate-overview {
  width: 462px;
  border-radius: 1px;
  padding: 20px;
  border: 1px solid;
  @include background-opacity($ts2, 0.95);
  @include border-opacity($ts3, 0.3);
  @extend %align-center;

  > .info-affiliate {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    > div:nth-of-type(1) {
      display: flex;
      margin-bottom: 12px;

      p {
        line-height: 40px;
        @extend %font-sm;
        color: $ts10;
      }

      img {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        margin-right: 10px;
      }
    }

    > div:nth-of-type(2) {
      display: flex;
      flex-direction: column;
      padding: 12px;
      @include background-opacity($ts1, 0.5);

      > div {
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;

        > p {
          @extend %font-xs;
          color: $ts10;
        }

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }

  > .info-products {
    display: flex;
    flex-direction: column;

    > div:nth-of-type(1) {
      margin-bottom: 8px;

      p {
        @extend %font-xs;
        color: $ts10;
      }
    }

    > div:nth-of-type(2) {
      display: flex;
      flex-direction: column;
      @include background-opacity($ts1, 0.5);

      > div {
        padding: 0px 12px;
        display: flex;
        border-bottom: 1px solid $ts2;
        height: 38px;

        img {
          height: 24px;
          width: 24px;
          margin-right: 8px;
          @extend %align-vertical;
        }

        &.tradesoftware,
        &.tradesoftware_customer {
          img {
            height: 18px;
            width: 18px;
            margin-left: 3px;
            margin-right: 11px;
          }
        }

        p {
          @extend %font-xs;
          line-height: 38px;
          color: $ts10;
        }
      }
    }

    > div:nth-child(3) {
      > hr {
        border-bottom: 1px solid;
        @include border-opacity($ts3, 0.3);
        border-top: none;
        border-left: none;
        border-right: none;
        margin: 20px 0px;
      }

      > button {
        @extend %align-horizontal;
        padding: 0px 30px;
      }
    }
  }
}
//media modal box-affiliate
@media screen and (max-width: $breakpoint-width-xs) {
  .box-affiliate-overview {
    width: 96%;
  }
}
//media modal accept terms
@media screen and (max-width: $breakpoint-width-s) {
  .box-modal-home {
    .pending-agreement {
      width: 100%;
    }
  }
  //pendingof products
  .pending-agreement {
    width: 100%;
    left: 0;
    top: 0;
    transform: translate(0%, 0%);
    margin-top: 32px;
  }
}
