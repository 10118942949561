.box-accept-terms {
  display: flex;
  justify-content:center;
  align-items:center;
  height: 100vh;
  padding:20px;
    > div:nth-child(1) {
      display: flex;
      flex-direction: column;
      border-radius:16px;
      height: auto;
      width:100%;
      padding:30px;
      max-width: 400px;
      background: $ts10;
      > div:nth-child(1) {
        margin-bottom: 40px;
        >img{
          width:100%;
          max-width: 250px;
          @extend %align-horizontal;
        }
      }
      > div:nth-child(2) {
        margin-bottom: 40px;
        > p:nth-child(1) {
          @extend %font-l;
          color: $ts2;
          margin-bottom: 40px;
        }
        > img:nth-child(2) {
          @extend %align-horizontal;
          max-width: 150px;
          object-fit:fill;
          margin-bottom: 40px;
        }
        > p:nth-child(3) {
          @extend %font-sm-bold;
          color: $ts2;
          > a {
            @extend %font-link;
          }
        }
      }
      > div:nth-child(3) {
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;
        > div {
          display: flex;
          > label {
            @extend %font-sm;
            color: $ts2;
            cursor: pointer
          }
          > input {
            margin-right: 4px;
            cursor: pointer;
          }
          &:first-child {
            margin-bottom: 12px;
          }
        }
      }
      > button {
        width:100%;
        max-width: 324px;
        min-height: 36px;
      }
    }

}

//Media queries
@media screen and (max-height: 680px) {
  .box-accept-terms{
    align-items: initial;
    >div:nth-child(1){
      height: auto;
      overflow-y:auto;
    }
  }
}

@media screen and (max-width: 440px) {
  .box-accept-terms{
    >div:nth-child(1){
      >div:nth-child(2){
        >img{
          width:90px;
        }
      }
    }
  }
}
