.box-switch-platform{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 387px;
  background: $ts2;
  border-radius: 16px;
  padding: 32px;
  @extend %align-center;
  >.title{
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    >img{
      width: 183px;
      margin-right: 24px;
    }
    >div:nth-of-type(1){
      width:1px;
      height: 24px;
      border-left:1px solid $ts3;
      margin-right: 24px;
    }
    >div:nth-of-type(2){
      display:flex;
      justify-content: center;
      align-items: center;
      @extend %font-m-bold;
      color:$ts10;
      text-transform: uppercase;
      width:75px;
      padding:10px 0px;
      border-radius: 16px;
    }
    &.stake{
      >div:nth-of-type(2){
        background:$success;
      }
    }
    &.swap{
      >div:nth-of-type(2){
        background:$primary;
      }
    }
  }
  >p:nth-of-type(1){
    @extend %font-m;
    color:$ts10;
    text-align: center;
    margin-bottom: 24px;
  }
  >.go{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    border-radius: 8px;
    @extend %font-m;
    color:$ts10;
    cursor:pointer;
    margin-bottom: 24px;
    &.stake{
      background:$success;
    }
    &.swap{
      background:$primary;
    }
  }
  >p:nth-of-type(2){
    @extend %font-m-bold;
    @extend %font-link;
    color:$ts10;
    cursor: pointer;
  }
}
