.box-password-recovery {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    padding: 20px;
    .password-recovery-initial {
      background:$ts10;
      border-radius: 16px;
      height: auto;
      padding:30px;
      overflow:auto;
      > div:nth-child(1) {
        margin-bottom: 40px;
        >img{
          width:100%;
          max-width: 250px;
        }
      }
      > div:nth-child(2) {
        display: flex;
        flex-direction: column;
        > p:nth-child(1) {
          @extend %font-l;
          color: $ts1;
          margin-bottom: 28px;
        }
        > p:nth-child(2) {
          @extend %font-s;
          color: $ts1;
          margin-bottom: 16px;
        }
        > div:nth-child(3) {
          margin-bottom: 16px;
          > input {
            color: $ts3;
            &::placeholder {
              color: $ts3;
            }
          }
        }
        > button:nth-child(4) {
          width: 120px;
          margin-bottom: 8px;
        }
        >:nth-child(6){
          .response-signup{
            margin-bottom: 30px;
          }
        }
        > a {
          cursor: pointer;
          @extend %font-s;
          @extend %font-link;
        }
      }
    }
    .password-recovery-sent {
      display:flex;
      flex-direction: column;
      align-items:center;
      background:$ts10;
      border-radius: 16px;
      height: auto;
      width:100%;
      max-width: 400px;
      padding:30px 30px 30px 30px;
      overflow:auto;
      > div:nth-child(1) {
        display:flex;
        justify-content:center;
        width:100%;
        margin-bottom: 40px;
        >img{
          width:100%;
          max-width: 250px;
        }
      }
      > div:nth-child(2) {
        display: flex;
        flex-direction: column;
        align-items: center;
        width:100%;
        > p:nth-child(1) {
          @extend %font-l;
          color: $ts1;
          margin-bottom: 28px;
        }
        > p:nth-child(2) {
          @extend %font-s;
          color: $ts1;
          margin-bottom: 20px;
          > img {
            margin-top: -8px;
            margin-right: 4px;
          }
        }
        > a {
          cursor: pointer;
          @extend %font-s;
          @extend %font-link;
        }
      }
    }
    .password-recovery-change {
      display:flex;
      flex-direction: column;
      align-items:center;
      background:$ts10;
      border-radius: 16px;
      height: auto;
      width:100%;
      max-width: 400px;
      padding:30px 30px 0px 30px;
      overflow:auto;
      > div:nth-child(1) {
        width:100%;
        margin-bottom: 40px;
        >img{
          width:100%;
          max-width: 250px;
        }
      }
      > div:nth-child(2) {
        display: flex;
        width:100%;
        flex-direction: column;
        > p:nth-child(1) {
          @extend %font-l;
          color: $ts1;
          margin-bottom: 28px;
        }
        > p:nth-child(2) {
          @extend %font-s;
          color: $ts1;
          margin-bottom: 16px;
        }
        > div {
          margin-bottom: 16px;
          > input {
            color: $ts3;
            &::placeholder {
              color: $ts3;
            }
          }
          &:last-child {
            margin-bottom: 20px;
          }
        }
        > button:nth-child(5) {
          width: auto;
          margin-bottom: 32px;
        }
        > a {
          cursor: pointer;
          @extend %font-s;
          @extend %font-link;
        }
      }
    }
    .password-recovery-finished {
      display:flex;
      flex-direction: column;
      align-items:center;
      background:$ts10;
      border-radius: 16px;
      height: auto;
      width:100%;
      max-width: 400px;
      padding:30px 30px 30px 30px;
      overflow:auto;
      > div:nth-child(1) {
        display:flex;
        justify-content:center;
        width:100%;
        margin-bottom: 40px;
        >img{
          width:100%;
          max-width: 250px;
        }
      }
      > div:nth-child(2) {
        display: flex;
        flex-direction: column;
        align-items:center;
        width:100%;
        > p:nth-child(1) {
          @extend %font-l;
          color: $ts1;
          margin-bottom: 28px;
        }
        > p:nth-child(2) {
          @extend %font-s;
          color: $ts1;
          margin-bottom: 16px;
        }
        > div {
          margin-bottom: 16px;
        }
        > a {
          cursor: pointer;
          @extend %font-s;
          @extend %font-link;
        }
      }
    }
    .response-signup{
      margin-bottom: 0px !important;
      margin-top: 12px;
    }
}

@media screen and (max-height: 400px) {
  .box-password-recovery{
    align-items: initial;
      >.password-recovery-initial {
        height: auto;
      }
  }
}

@media screen and (max-height: 490px) {
  .box-password-recovery{
    align-items: initial;
      >.password-recovery-change {
        height: auto;
      }
  }
}
