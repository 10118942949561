.box-swap-deposit-modal {
  display: flex;
  flex-direction: column;
  width: 480px;
  background: $ts2;
  border-radius: 16px;
  padding: 24px;
  @extend %align-center;
  >.title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    >p {
      @extend %font-l;
      color: $ts10;
    }
    >img{
      cursor:pointer;
    }
  }

  >.balance {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    >img {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }

    >p:nth-of-type(1) {
      @extend %font-xs;
      color: $ts10;
      line-height: 24px;
    }

    >p:nth-of-type(2) {
      margin-left: auto;
      @extend %font-xs;
      color: $ts10;
      line-height: 16px;

      >span {
        @extend %font-xs;
        color: $ts3;
      }
    }
  }

  >.adress {
    display: flex;
    flex-direction: row;
    position: relative;
    padding: 24px;
    @include background-opacity($ts1, 0.9);
    border: 1px solid;
    @include border-opacity($ts3, 0.2);
    border-radius: 16px;

    >.qr-code {

      padding: 8px;
      margin-right: 16px;
      @include background-opacity($ts20, 0.4);
      border-radius: 8px;

      >svg {
        height: 96px;
        width: 96px;
      }
    }
    >.content {
      display: flex;
      flex-direction: column;

      >div {
        display: flex;
        flex-direction: column;

        >p:nth-of-type(1) {
          @extend %font-xs;
          color: $ts3;
          margin-bottom: 8px;
        }

        >p:nth-of-type(2) {
          @extend %font-xs;
          color: $ts10;
          word-break:break-all;
          >img {
            margin-right: 8px;
          }
        }

        &:first-child {
          margin-bottom: 12px;
          cursor: pointer;
        }
      }
    }
    >.copy-tooltip {
      position: absolute;
      top: 12px;
      right: 12px;
      background: $ts2;
      padding: 4px 10px;
      border-radius: 1px;
      border: 1px solid $success;

      p {
        @extend %font-xxs;
        color: $ts10;
      }
    }
  }
  //Modal alert adress bep20
  >.close-alert{
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 18px;
    > img {
      cursor: pointer;
    }
  }
  >.content-alert {
    display: flex;
    align-items: center;
    margin-bottom: 22px;
    >img{
      height: 40px;
      width: 40px;
      margin-right: 18px;
    }
    >div{
      >p:nth-of-type(1) {
        @extend %font-l;
        color:$ts10;
        margin-bottom: 8px;
      }
      >p:nth-of-type(2) {
        @extend %font-xs;
        color:$ts10;
      }
    }
  }
  >.agree-alert{
    display: flex;
    margin-bottom: 22px;
    >label{
      position: relative;
      padding-left:24px;
      @extend %font-sm;
      color:$ts10;
      cursor: pointer;
      >input[type="checkbox"]{
        visibility: hidden;
      }
      >.selector-area{
        display: flex;
        justify-content: center;
        align-items: center;
        position:absolute;
        top:0px;
        left:0px;
        background:transparent;
        border: 1px solid $ts3;
        margin-right: 8px;
        width:16px;
        height: 16px;
        border-radius: 50%;
      }
      >input:checked{
        ~ .selector-area{
          >.circle{
            width:10px;
            height: 10px;
            border-radius: 50%;
            @include background-opacity($primary, 0.5);
          }

        }
      }
    }
  }
  >.button-alert{
    display: flex;
    >button{
      width:100%;
      border-radius: 8px;
      @extend %font-m-bold;
    }
  }
  @media screen and (max-width: $breakpoint-width-s){
    width: 100%;
    transform: translate(-50%, 0%);
    top:0px;
  }
}
