body {
  background-image: url('../../assets/images/main/bg.jpg');
  // background:$ts1;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 100vh;
  overflow:overlay;
  &.swap{
    // background-color: $ts1;
    background-image: url('../../assets/images/main/bg.jpg');
  }
}

.container {
  width: 1080px;
  margin: 0px auto;
  position: relative;
}

.box-view {
  display: flex;
  flex-direction: column;
  min-height: 90vh;
  padding:48px;
  padding-bottom: 104px;
  background:$ts1;
  border-radius: 8px;
  border:1px solid $ts2;
  border-bottom:1px solid $ts1;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  >.heading-tab{
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    img{
      height: 24px;
      width: 24px;
      margin-right: 8px;
    }
    p{
      @extend %font-l;
      color:$ts10;
    }
    >.tab-panel{
      display:flex;
      margin-left: auto;
      >p{
        @extend %font-m;
        color:$ts3;
        cursor:pointer;
        margin-right: 16px;
        &.active{
          color:$ts10;
          border-bottom: 2px solid $primary;
        }
        &:last-child{
          margin-right: 0px;
        }
      }
    }
  }
  >.content {
    min-height: 77vh;
    padding: 32px 32px 0px 32px;
    @include background-opacity($ts2, 0.85);
    padding-bottom: 100px;
  }
}

.text-overflow-ellipsis {
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: bottom;
}

@include screenMaxWidth(600px){
  .container {
    padding:  0px !important;
  }
  .box-view{
    padding:16px 16px 110px 16px;
  }
}
@media screen and (max-width: $breakpoint-width-xl) {
  .container {
    width: 100%;
    padding:0px 20px;
  }
}
@media screen and (max-width: $breakpoint-width-s) {
  .container{
    .box-view{
      .content{
        padding:24px 24px 0px 24px;
      }
    }
  }
}
