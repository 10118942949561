%font-xxs {
  font-size: 12px;
  font-weight: 300;
  line-height:14px;
}

%font-xxs-bold {
  font-size: 12px;
  font-weight: 600;
  line-height:14px;
}

%font-xs {
  font-size: 13px;
  font-weight: 300;
}

%font-xs-bold {
  font-size: 13px;
  font-weight: 600;
}

%font-s {
  font-size: 14px;
  font-weight: 300;
  line-height:18px;

}

%font-s-bold {
  font-size: 14px;
  font-weight: 700;
  line-height:18px;
}

%font-sm {
  font-size: 16px;
  font-weight: 300;
}

%font-sm-bold {
  font-size: 16px;
  font-weight: 400;
}

%font-m {
  font-size: 18px;
  font-weight: 300;
  line-height:22px;
}

%font-m-bold {
  font-size: 18px;
  font-weight: 400;
  line-height:22px;
}

%font-l {
  font-size: 24px;
  font-weight: 300;
  line-height:36px;
}

%font-l-bold {
  font-size: 24px;
  font-weight: 400;
  line-height:36px;
}

%font-xl{
  font-size: 36px;
  font-weight: 300;
  line-height:54px;
}

%font-xl-bold {
  font-size: 36px;
  font-weight: 400;
  line-height:54px;
}

%font-xxl {
  font-size: 48px;
  font-weight: 400;
}

%font-link {
  text-decoration: underline;
  color: $primary;
  cursor: pointer;
}
%font-link-danger {
  text-decoration: underline;
  color: $danger;
  cursor: pointer;
}
%font-link-success {
  text-decoration: underline;
  color: $success;
  cursor: pointer;
}
