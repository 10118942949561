header {
  display: flex;
  flex-direction: column;

  .header-bar {
    height: 72px;
    width: 100%;
    z-index: 3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 20px;
    margin-bottom: 18px;
    >div:nth-child(1) {
      display: flex;

      .logo {
        margin-right: 20px;
        cursor: pointer;

        >img {
          height: 48px;
          object-fit: fill;
        }
      }

      .logo-mobile {
        display: none;
        cursor: pointer;

        >img {
          height: 36px;
          object-fit: fill;
        }
      }

      .information-menu {
        font-size: 13px;
        font-weight: 300;
        line-height: 24px;
        margin-left: 20px;
        @include color-opacity($ts10, 0.8);
      }

      .change-platform {
        display: flex;
        align-items: center;

        >div {
          width: 88px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          @extend %font-s-bold;
          text-transform: uppercase;
          border-radius: 50px;
          cursor: pointer;
        }

        >.stake {
          border: 1px solid $success;
          background: transparent;
          color: $success;
          margin-right: 12px;

          &.active {
            border: 1px solid $success;
            background: $success;
            color: $ts10;
          }
        }

        >.swap {
          border: 1px solid $primary;
          background: transparent;
          color: $primary;

          &.active {
            border: 1px solid $primary;
            background: $primary;
            color: $ts10;
          }
        }
      }
    }

    >div:nth-child(2) {
      display: flex;
      align-items: center;
      padding-left: 20px;

      .historic-swap {
        display: flex;
        margin-right: 18px;
        margin-top: 2px;
        cursor: pointer;

        >img {
          margin-right: 4px;
          margin-top: -2px;
        }

        >p {
          @extend %font-xxs;
          color: $ts10;
        }
      }

      .bsc-network {
        display: flex;
        align-items: center;
        width: 110px;
        height: 28px;
        background: $ts2;
        position: relative;
        border-radius: 50px;
        margin-right: 18px;
        padding-left: 28px;

        >img {
          position: absolute;
          left: 2px;
        }

        p {
          @extend %font-xs;
          color: $ts10;
        }
      }

      .notification {
        position: relative;
        margin-right: 28px;

        >span {
          display: flex;
          justify-content: center;
          align-items: center;
          @extend %font-xxs;
          color: $ts10;
          background: $danger;
          position: absolute;
          top: -4px;
          right: -12px;
          border-radius: 50%;
          padding: 2px 6px;
        }

        >.dropdown {
          visibility: hidden;
          display: flex;
          flex-direction: column;
          width: 300px;
          height: auto;
          background: $ts1-2;
          border: 1px solid $ts1-3;
          border-radius: 16px;
          position: absolute;
          top: 40px;
          left: -200px;
          transition: 0.3s;
          opacity: 0;

          .title {
            display: flex;
            align-items: center;
            height: 40px;
            padding: 0px 18px;
            border-bottom: 1px solid $ts1-3;

            p {
              @extend %font-s-bold;
              color: $ts10;
            }
          }

          >.content {
            display: flex;
            flex-direction: column;
            padding: 18px;

            >div {
              display: flex;
              flex-direction: column;
              border-bottom: 1px solid $ts1-3;
              margin-bottom: 8px;
              cursor: pointer;

              >p:nth-of-type(1) {
                @extend %font-s;
                color: $ts10;
                margin-bottom: 4px;
              }

              >p:nth-of-type(2) {
                @extend %font-s;
                color: $ts3;
                margin-bottom: 4px;
              }

              &:last-child {
                border-bottom: 0px;
                margin-bottom: 0px;
              }
            }
          }

          .view-more {
            padding: 0px 18px 18px 18px;
            @extend %font-s;
            color: $primary;
            cursor: pointer;
          }
          &.no-message{
            >.title{
              border-bottom:0px;
              justify-content:center;
            }
            >.content,.view-more{
              display: none;
            }
          }
        }

        &:hover {
          >.dropdown {
            visibility: visible;
            opacity: 1;
          }
        }

      }

      .profile {
        margin-right: 18px;

        >a {
          >img {
            width: 23px;
            height: 23px;
            border-radius: 50%;
          }
        }
      }

      .signout {
        height: 100%;
        cursor: pointer;
        >img{
          height: 28px;
          width: 28px;
        }
      }

      .menu-mobile {
        display: none;
      }
    }

    &.mobile {
      height: 40px;
      padding: 9px 12px;
    }
  }

  .has-debits {
    height: auto;
    width: 100%;
    padding: $padding-orange;
    z-index: 2;
    top: 40px;
    @include background-opacity($info, 0.85);
    position: relative;
    display: flex;

    .title {
      display: flex;
      margin-right: $margin-red * 1.5;

      >div:nth-child(1) {
        margin-right: 12px;

        svg {
          width: 24px;
          height: 20px;
          @extend %align-vertical;
        }
      }

      >div:nth-child(2) {
        p {
          @extend %font-l;
          color: $ts10;
          @extend %align-vertical;
        }
      }
    }

    .description {
      max-width: 460px;
      margin-right: $margin-red * 2;
      display: flex;
      flex-direction: column;
      justify-content: center;

      p {
        @extend %font-s;
        line-height: 18px;
        color: $ts10;

        &.important {
          font-weight: bold;
        }
      }
    }

    .details {
      display: flex;

      >div {
        width: 140px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        p {
          @extend %font-xs;
          line-height: 18px;
          color: $ts10;

          &:first-child {
            font-weight: bold;
          }
        }
      }
    }

    .action-button {
      display: flex;
      height: 20px;
      position: absolute;
      right: 20px;
      @extend %align-vertical;
      cursor: pointer;

      a {
        height: 20px;
        padding: 2px 20px;
        @extend %font-s;
        color: $ts10;
        border: 1px solid $ts10;
        border-radius: 2px;
      }
    }

    &.expired {
      @include background-opacity($danger, 0.97);
    }
  }

  .maintenance-bar{
    display: flex;
    justify-content: center;
    align-items: center;
    @include background-opacity($warning, 0.9);
    padding:18px 14px;
    p{
      @extend %font-s;
      font-size: 16px;
      color:$ts1;
      text-align: center;
    }
  }
}

@media screen and (max-width: $breakpoint-width-m) {
  header {
    .header-bar {
      >div:nth-of-type(1) {
        >div:nth-of-type(5) {
          display: none;
        }

        >div:nth-of-type(6) {
          display: none;
        }
      }

      >div:nth-of-type(2) {
        >.bsc-network {
          display: none;
        }
      }
    }

    &.ios {
      .header-bar {
        height: 70px;
        position: fixed;
        padding: 40px 12px 8px 12px;
      }
    }
  }
}



@media screen and (max-width: $breakpoint-width-s) {
  header {
    .header-bar {
      >div:nth-of-type(1) {
        .logo {
          display: none;
        }

        .logo-mobile {
          display: block;
        }

        .change-platform {
          display: none;
        }
      }

      >div:nth-of-type(2) {

        >.bsc-network,
        .profile,
        .signout,
        .historic-swap {
          display: none;
        }

        >.menu-mobile {
          display: block;

          >.menu-opened {
            display: flex;
            flex-direction: column;
            position: fixed;
            width: 100vw;
            height: 100vh;
            left:610px;
            top: 0px;
            background: $ts1;
            padding: 12px 24px 0px 24px;
            visibility: collapse;
            transition: 1s;
            >div:nth-of-type(1) {
              display: flex;
              justify-content: space-between;
              margin-bottom: 40px;

              >img:nth-of-type(1) {
                height: 37px;
              }
            }
            >div:nth-of-type(2) {
              display: flex;
              margin-bottom: 40px;

              >img {
                margin-right: 12px;
              }

              >p {
                font-size: 22px;
                font-weight: 300;
                color: $ts10;
                line-height: 40px;
              }
            }
            >div:nth-of-type(n+3):nth-of-type(n-4) {
              display: flex;
              >div {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 24px;
                width: 24px;
                border-radius: 50%;
                border: 1px solid $ts10;
                margin-right: 12px;
                margin-bottom: 18px;

                &.active {
                  >div {
                    height: 16px;
                    width: 16px;
                    border-radius: 50%;
                  }
                }
                &.stake.active{
                  >div{
                    background:$primary;
                  }
                }
                &.swap.active{
                  >div{
                    background:$success;
                  }
                }
              }
              >p {
                font-size: 18px;
                font-weight: 300;
                color: $ts10;
                line-height: 24px;
              }
            }
            >div:nth-of-type(n+5):nth-of-type(n-6){
              display: flex;
              margin-bottom: 18px;
              >img{
                height: 24px;
                width: 24px;
                margin-right: 12px;
              }
              >p{
                font-size: 18px;
                font-weight: 300;
                color:$ts10;
              }
            }
            &.active {
              visibility: visible;
              transition: 1s;
              left:0px;
              top:0px;
            }
          }
        }
      }
    }
  }
}
