//marketplace
.box-contract{
  background:$ts1-1;
  border:1px solid $ts2;
  border-radius:16px;
  padding:40px 40px 76px 40px;
  border-bottom: 1px solid $ts1-1;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  >.contract-container{
    display:flex;
    margin-bottom: 40px;
    >.logo{
      display:flex;
      flex-direction:column;
      margin-right:60px;
      >img{
        width: 192px;
        height: 192px;
        margin-bottom: 32px;
      }
      >.cake-price{
        display: flex;
        flex-direction: column;
        align-items:center;
        >p:nth-of-type(1){
         @extend %font-s;
         color:$ts3;
         margin-bottom: 4px;
        }
        >p:nth-of-type(2){
         @extend %font-l-bold;
         color:$ts10;
        }
      }
    }
    >.description{
      display: flex;
      flex-direction: column;
      max-width: 390px;
      >p:nth-of-type(1){
        @extend %font-xxl;
        color:$ts10;
        text-align: left;
        margin-top:28px;
        margin-bottom: 32px;
      }
      >p:nth-of-type(2){
        @extend %font-sm;
        color:$ts10;
        text-align: left;
        line-height: 20px;
      }
    }
    >.avarage-profit{
      display: flex;
      flex-direction: column;
      position: absolute;
      right: 40px;
      width:264px;
      background:$ts2;
      padding:20px;
      border-radius: 8px;
      >p:nth-of-type(1){
        @extend %font-sm-bold;
        color:$ts10;
        text-align: center;
        margin-bottom: 14px;
      }

      >.timeframe{
        margin-bottom: 8px;
        >div{
          display: flex;
          justify-content: space-between;
          p{
            line-height:24px;
            @extend %font-s;
          }
          p:nth-of-type(1){
            color:$ts10;
          }
          p:nth-of-type(2){
            color:$success;
          }
          &.title{
            >p{
              @extend %font-s;
              color:$ts3;
            }
          }
          &.obs{
            margin-top: 4px;
            >p{
              @extend %font-s;
              color:$ts3;
              line-height: 15px;
            }
          }
        }
      }

      >.fee{
        display: flex;
        flex-direction: column;
        >div{
          display: flex;
          justify-content:space-between;
          margin-bottom: 8px;
          >p{
            @extend %font-s;
            color:$ts10;
          }
        }
        >p{
          @extend %font-s;
          color:$ts3;
        }
      }
    }
  }
  >.start-contract-container{
    display: flex;
    flex-direction: column;
    padding:24px;
    background:$ts1-2;
    border-radius: 8px;
    margin-bottom: 22px;
    >.adress-start{
      display: flex;
      justify-content:space-between;

      >.payment{
        position: relative;
        display: flex;
        flex-direction: column;
        width:49.5%;
        padding:24px;
        border-radius: 8px;
        background: $ts1;
        // margin-bottom: 40px;
        >p:nth-child(1) {
          @extend %font-l-bold;
          color:$ts10;
          margin-bottom: 14px;
        }
        >.separator{
          margin: 8px;
          color:$ts3;
          text-align: center;
        }
        >.input-large {
          height: 54px;
          margin-bottom: 24px;
          position: relative;
          >input{
            background:$ts2;
            border-radius: 8px;
            p{
              @extend %font-m;
              color:$ts10;
              text-align: center;
              line-height:54px;
            }
          }
          >p:nth-child(2){
            position: absolute;
            @extend %font-xxs;
            color:$primary;
            cursor: pointer;
            top: 22px;
            right: 18px;
          }
          >p:nth-child(3){
            position: absolute;
            @extend %font-xxs;
            color:$danger;
            bottom: -16px;
            right: 0px;
          }
        }
        >.balance {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 24px;
          >div:nth-child(1) {
            p {
              color:$ts3;
            }
          }
          >div:nth-child(2) {
            cursor: pointer;
            p {
              color:$ts10;
            }
          }
        }
        >.adress{
          display: flex;
          position: relative;
          >div:nth-of-type(1){
            margin-right: 12px;
          }
          >div:nth-of-type(2){
            display: flex;
            flex-direction: column;
            >div{
              display: flex;
              flex-direction: column;
              >p:nth-child(1){
                @extend %font-s;
                color:$ts3;
                margin-bottom: 8px;
              }
              >p:nth-child(2){
                @extend %font-xs-bold;
                color:$ts10;
                margin-bottom: 14px;
                word-break: break-all;
                >img{
                  margin-right: 8px;
                }
              }
              &:last-child{
                >p:nth-child(2){
                  margin-bottom: 0px;
                }
              }
              &:first-child{
                >p:nth-of-type(2){
                  cursor: pointer;
                }
             }
            }
          }
          >.copy-tooltip{
            position: absolute;
            top:-7px;
            right: 8px;
            background: $ts2;
            padding: 4px 10px;
            border-radius: 1px;
            border: 1px solid $success;

            p {
              @extend %font-xxs;
              color: $ts10;
            }
          }
        }
        >.success-tooltip{
          position: absolute;
          top:12px;
          right: 12px;
          background: $ts2;
          padding: 4px 10px;
          border-radius: 1px;
          border: 1px solid $success;

          p {
            @extend %font-xxs;
            color: $ts10;
          }
        }
      }
      >.info{
        display: flex;
        flex-direction: column;
        width:49.5%;
        padding:0px 24px 24px 24px;
        >p:nth-of-type(1){
          @extend %font-l-bold;
          color:$ts10;
          margin-bottom: 16px;
        }
        li{
          @extend %font-s;
          line-height: 15px;
          color:$ts10;
          list-style-type: disc;
          margin-left: 16px;
          margin-bottom: 4px;
        }
        >p{
          @extend %font-s;
          color:$ts10;
          margin-top: 10px;
        }
      }
    }
    >.alert{
      display: flex;
      align-items: center;
      padding:24px;
      background:$ts1;
      border:1px solid $danger;
      border-radius: 8px;
      >img{
        width:40px;
        height: 40px;
        margin-right: 24px;
      }
      >div{
        display: flex;
        flex-direction: column;
        >div{
          display: flex;
          flex-direction: column;
          margin-bottom: 24px;
          >li{
            color:$ts10;
            >span{
              @extend %font-s-bold;
              color:$ts10;
              margin-left: -10px;
              text-transform: uppercase;
            }
          }
          >p{
            @extend %font-s;
            color:$ts10;
          }
          &:last-child{
            margin-bottom: 0px;
          }
        }
      }
    }
  }
  >.my-contracts{
    >.title{
      display: flex;
      justify-content: space-between;
      >p{
        @extend %font-l;
        color:$ts10;
        margin-bottom: 22px;
      }
      >button{
        margin-top: 4px;
      }
    }
    >.contract{
      display: flex;
      padding:24px 20px 24px 20px;
      background:$ts1-2;
      height: 120px;
      border-radius: 8px;
      margin-bottom: 12px;
      >.logo{
        margin-right: 16px;
        >img{
          width:32px;
          height: 32px;
          @extend %align-center;
          margin-right: 16px;
        }
      }
      >.info{
        display: flex;
        flex-direction: column;
        margin-right: 18px;
        max-width: 170px;
        >div{
          display: flex;
          margin-bottom: 14px;
          >p:nth-child(1){
            width:40px;
            @extend %font-xxs;
            color:$ts3;
          }
          >p:nth-child(2){
            @extend %font-xxs;
            color:$ts10;
            max-width: 131px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &.info{
              color:$info;
            }
            &.success{
              color:$success;
            }
            &.warning{
              color:$warning;
            }
            &.danger{
              color:$danger;
            }
          }
        }
      }
      >.amount{
        display:flex;
        flex-direction: column;
        justify-content:center;
        width: 100px;
        background:$ts1-1;
        border-radius: 8px;
        padding:0px 16px;
        margin-right: 24px;
        p:nth-of-type(1){
          @extend %font-xxs;
          color:$ts3;
          text-align: center;
          margin-bottom: 12px;
        }
        p:nth-of-type(2){
          @extend %font-xxs;
          text-align: center;
          color:$ts10;
        }
      }
      >.day-period, .fee, .profit, .profit-liquid{
        display: flex;
        width:100px;
        flex-direction: column;
        justify-content: space-between;
        >div{
          display: flex;
          flex-direction: column;
          >p:nth-child(1){
            margin-bottom: 2px;
            @extend %font-xxs;
            color:$ts3;
          }
          >p:nth-child(2){
            @extend %font-xxs;
            color:$ts10;
          }
        }
      }
      >.day-period{
        width:86px;
      }
      >.profit{
        width:118px;
      }
      >.fee{
        width:110px;
      }
      >.profit-liquid{
        width:132px;
        margin-right: 8px;
      }
      >.button-action{
        display: flex;
        flex-grow: 1;
        align-items:center;
        justify-content:center;
      }

    }
  }
}
//media marketplace
@include screenMaxWidth(950px){
  .box-contract{
    >.contract-container{
      flex-direction: column;
      align-items: center;
      >.logo{
        margin-right: 0px;
        >img{
          margin-bottom: 16px;
        }
      }
      >.description{
        margin-bottom: 16px;
        >p:nth-of-type(1){
          text-align: center;
          margin:16px 0px;
        }
        >p:nth-of-type(2){
          text-align: center;
        }
      }
      >.avarage-profit{
        position: relative;
        right: 0px;
      }
    }
    >.start-contract-container{
      >.adress-start{
        flex-direction: column;
        >.payment{
          width:100%;
        }
        >.info{
          width: 100%;
          padding:0px;
          margin-bottom: 24px;
        }
      }
    }
    >.my-contracts{
      >.contract{
        width:auto;
        overflow-x: auto;
        >div{
          flex-shrink: 0;
        }
        >.profit-liquid{
          margin-right: 0px;
        }
        >.button-action{
          min-width: 164px;
        }
      }
    }
  }
}
@include screenMaxWidth(500px){
  .box-contract{
    padding: 16px 16px 84px 16px;
    >.contract-container{
      >.logo{
        >img{
          height: 128px;
          width:128px;
        }
      }
      >.description{
        >p:nth-of-type(1){
          font-size: 38px;
        }
      }
      >.avarage-profit{
        width:100%;
      }
    }
    >.start-contract-container{
      padding:16px;
      >.adress-start{
        >.payment{
          padding:16px;
          >.min-deposit{
            >p{
              font-size: 14px;
            }
          }
          >.adress{
            flex-direction: column;
            align-items: center;
            >div:nth-of-type(1){
              margin-right: 0px;
              margin-bottom: 12px;
            }
          }
        }
      }
      >.alert{
        >img{
          display: none;
        }
      }
    }
  }
}


