.box-swap-history{
  display: flex;
  flex-direction: column;
  >.back{
    margin-bottom: 8px;
    >div{
      display: flex;
      max-width: 100px;
      img{
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
      p{
        @extend %font-s-bold;
        color:$ts10;
        line-height: 24px;
      }
    }
  }
  >.history-transactions{
    height: auto;
    width: 100%;
    padding:24px;
    margin-bottom: 24px;
    @include background-opacity($ts2, 0.2);
    border:1px solid;
    @include border-opacity($ts3, 0.2);
    border-radius: 16px;
    > div:nth-child(1) {
      padding: 12px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $ts2;

      .title {
        p {
          @extend %font-l;
          color: $ts10;
        }
      }

      .filter {
        display: flex;

        > div:nth-child(1) {
          width: 152px;
          margin-right: 12px;
        }

        > div:nth-child(2) {
          display: flex;
          margin-right: 8px;

          > p {
            color: $ts10;
            @extend %font-s;
            line-height: 27px;
            margin-right: 4px;
          }

          input {
            &::-webkit-calendar-picker-indicator {
              &:hover {
                background-color: transparent;
                cursor: pointer;
              }
            }

            &::-webkit-clear-button {
              display: none;
            }

            &::-webkit-inner-spin-button {
              display: none;
            }
          }
        }

        > div:nth-child(3) {
          display: flex;

          > p {
            color: $ts10;
            @extend %font-s;
            line-height: 27px;
            margin-right: 4px;
          }

          input {
            &::-webkit-calendar-picker-indicator {
              &:hover {
                background-color: transparent;
                cursor: pointer;
              }
            }

            &::-webkit-clear-button {
              display: none;
            }

            &::-webkit-inner-spin-button {
              display: none;
            }
          }
        }
      }
    }

    > div:nth-child(2) {
      > table {
        width:100%;
        tr {
          border-bottom: 1px solid $ts2;
          width: 100%;

          th {
            text-align: left;
            color: $ts3;
            @extend %font-s-bold;
            padding: 8px 0px;

            &:nth-child(1) {
              padding-left: 12px;
            }

          }

          td {
            text-align: left;
            color: $ts10;
            @extend %font-xs;
            padding: 12px 0px;

            &:nth-child(1) {
              padding-left: 12px;
              padding-right: 30px;
              max-width:140px;
              white-space:nowrap;
              overflow:hidden;
              text-overflow:ellipsis;
              cursor: pointer;
            }
            &:nth-child(2){
              max-width: 110px;
            }
            &:nth-child(6) {

              > a {
                @extend %font-link;
              }
            }
            span {
              &.confirmed {
                color: $success;
              }

              &.waiting {
                color: $info;
              }

              &.success {
                color: $success;
              }
              &.danger {
                color: $danger;
              }
            }

            &.adress {
              color: $primary;
              cursor: pointer;

              > img {
                margin-left: 5px;
              }
            }
          }
          &:last-child{
            border-bottom: 0px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: $breakpoint-width-m) {
  .box-swap-history {
    .history-transactions {
      overflow-x: auto;

      > div {
        min-width: 800px;
      }
    }
  }
}
