.box-files {
  .files {
    height: auto;
    width: 100%;
    > div:nth-child(1) {
      background: $ts1;
      padding: 12px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $ts2;
      .title {
        p {
          @extend %font-l;
          color: $ts10;
        }
      }
      .filter {
        display: flex;
        > div:nth-child(1) {
          width: 152px;
          margin-right: 12px;
        }
        > div:nth-child(2) {
          display: flex;
          margin-right: 8px;
          > p {
            color: $ts10;
            @extend %font-s;
            line-height: 27px;
            margin-right: 4px;
          }
          input {
            &::-webkit-calendar-picker-indicator {
              &:hover {
                background-color: transparent;
                cursor: pointer;
              }
            }
            &::-webkit-clear-button {
              display: none;
            }
            &::-webkit-inner-spin-button {
              display: none;
            }
          }
        }
        > div:nth-child(3) {
          display: flex;
          > p {
            color: $ts10;
            @extend %font-s;
            line-height: 27px;
            margin-right: 4px;
          }
          input {
            &::-webkit-calendar-picker-indicator {
              &:hover {
                background-color: transparent;
                cursor: pointer;
              }
            }
            &::-webkit-clear-button {
              display: none;
            }
            &::-webkit-inner-spin-button {
              display: none;
            }
          }
        }
      }
    }
    > div:nth-child(2) {
      > table {
        background: $ts1;
        tr {
          border-bottom: 1px solid $ts2;
          width: 100%;
          td {
            text-align: left;
            height: 48px;
            line-height: 48px;
            &:nth-child(1) {
              width: 198px;
              padding-left: 12px;
              @extend %font-s-bold;
              @include color-opacity($ts10, 0.9);
            }
            &:nth-child(2) {
              width: 667px;
              line-height: 20px;
              @extend %font-xs;
              @include color-opacity($ts10, 0.9);
            }
            &:nth-child(3) {
              width: 151px;
              text-align: right;
              padding-right: 12px;
              @extend %font-xs;
              color: $primary;
              > span {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-width-m) {
  .box-files {
    .files {
      overflow-x: auto;
      > div {
        min-width: 850px;
      }
    }
  }
}
