.box-swap-state{
  display: flex;
  flex-direction: column;
  width: 420px;
  @include background-opacity($ts2, 0.8);
  border:1px solid $ts2;
  border-radius: 16px;
  padding: 32px;
  @extend %align-center;
  >.title{
    @extend %font-m;
    color:$ts10;
    margin-bottom: 24px;
  }
  >.title-image{
    display: flex;
    flex-direction: column;
    align-items: center;
    >p{
      @extend %font-m;
      color:$ts10;
      text-align: center;
      margin-bottom: 24px;
    }
    >img{
      width:96px;
      height: 96px;
      margin-bottom: 8px;
    }
  }
  >.amount{
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    >div{
      display: flex;
      align-items: center;
      padding:20px;
      @include background-opacity($ts1, 0.8);
      height: 40px;
      border-radius: 8px;
      margin-bottom: 8px;
      >img{
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
      >p{
        @extend %font-s;
        color:$ts10;
      }
      >p:nth-of-type(2){
        margin-left: auto;
      }
    }
    >img{
      transform:rotate(270deg);
      width: 24px;
      height: 24px;
      margin-bottom: 8px;
      @extend %align-horizontal;
    }
  }
  >.obs{
    margin-bottom: 16px;
    >p{
      @extend %font-xs;
      color:$ts10;
    }
  }
  >.estimate{
    display: flex;
    flex-direction: column;
    @include background-opacity($ts1, 0.8);
    border-radius: 8px;
    padding:16px;
    margin-bottom: 16px;
    >div{
      display: flex;
      justify-content:space-between;
      margin-bottom: 8px;
      >p{
        @extend %font-xs;
        color:$ts10;
      }
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
  >.button-action{
    display: flex;
    flex-direction: column;
    width: 100%;
    >button{
      border-radius: 8px;
    }
  }
  >.loading{
    position: relative;
    >.slider{
      position:absolute;
      width: 100%;
      border-radius: 50%;
      height:5px;
      overflow-x: hidden;
      >.line {
        position:absolute;
        opacity: 0.4;
        background:$ts1;
        width:150%;
        height:5px;
      }
      >.subline{
        position:absolute;
        background:$success;
        height:5px;
        &.inc{
          animation: increase 2s infinite;
        }
        &.dec{
          animation: decrease 2s 0.5s infinite;
        }
        @keyframes increase {
          from { left: -5%; width: 5%; }
          to { left: 130%; width: 100%;}
        }
        @keyframes decrease {
          from { left: -80%; width: 80%; }
          to { left: 110%; width: 10%;}
        }
      }
    }
  }


  >div{
    &:last-child{
      margin-bottom: 0px;
    }
  }
  @media screen and (max-width: $breakpoint-width-s){
    width: 100%;
    transform: translate(-50%, 0%);
    top:0px;
  }

}
