//small
.input-small-icon {
  width: auto;
  position: relative;
  height: 24px;
  > input {
    width: 100%;
    height: 100%;
    padding: 0px 4px 0px 8px;
    color: $ts10;
    @extend %font-s;
    &::placeholder {
      color: $ts10;
    }
  }
  > select {
    width: 100%;
    height: 100%;
    padding: 0px 4px 0px 4px;
    color: $ts10;
    @extend %font-s;
    &::placeholder {
      color: $ts10;
    }
  }
  > img,
  svg {
    position: absolute;
    @extend %align-vertical;
    right: 4px;
  }
}
//small (input, select)
.input-small {
  width: auto;
  position: relative;
  height: 24px;
  > input {
    width: 100%;
    height: 100%;
    padding: 0px 4px 0px 8px;
    color: $ts10;
    @extend %font-s;
    &::placeholder {
      color: $ts10;
    }
  }
  > select {
    width: 100%;
    height: 100%;
    padding: 0px 4px 0px 4px;
    color: $ts10;
    @extend %font-s;
    &::placeholder {
      color: $ts10;
    }
  }
}
//medium
.input-medium-icon {
  width: auto;
  position: relative;
  height: 28px;
  > input {
    width: 100%;
    height: 100%;
    padding: 0px 8px 0px 12px;
    color: $ts10;
    @extend %font-s;
    &::placeholder {
      color: $ts10;
    }
  }
  > select {
    width: 100%;
    height: 100%;
    padding: 0px 8px 0px 12px;
    color: $ts10;
    @extend %font-s;
    &::placeholder {
      color: $ts10;
    }
  }
  > img,
  svg {
    position: absolute;
    @extend %align-vertical;
    right: 8px;
  }
}
//medium (input, select)
.input-medium {
  width: auto;
  position: relative;
  height: 28px;
  > input {
    width: 100%;
    height: 100%;
    padding: 0px 8px 0px 12px;
    color: $ts10;
    @extend %font-s;
    &::placeholder {
      color: $ts10;
    }
  }
  > select {
    width: 100%;
    height: 100%;
    padding: 0px 8px 0px 12px;
    color: $ts10;
    @extend %font-s;
    &::placeholder {
      color: $ts10;
    }
  }
}
//large
.input-large-icon {
  width: auto;
  position: relative;
  height: 36px;
  > input {
    width: 100%;
    height: 100%;
    padding: 0px 8px 0px 12px;
    color: $ts10;
    @extend %font-m;
    &::placeholder {
      color: $ts10;
    }
  }
  > select {
    width: 100%;
    height: 100%;
    padding: 0px 8px 0px 12px;
    color: $ts10;
    @extend %font-s;
    &::placeholder {
      color: $ts10;
    }
  }
  > img,
  svg {
    position: absolute;
    @extend %align-vertical;
    right: 8px;
  }
}

//large (input, select)
.input-large {
  width: auto;
  position: relative;
  height: 36px;
  > input {
    width: 100%;
    height: 100%;
    padding: 0px 8px 0px 12px;
    color: $ts10;
    @extend %font-s;
    &::placeholder {
      color: $ts10;
    }
  }
  > select {
    width: 100%;
    height: 100%;
    padding: 0px 8px 0px 12px;
    color: $ts10;
    @extend %font-s;
    &::placeholder {
      color: $ts10;
    }
  }
}

//textarea
.textarea-main {
  > textarea {
    width: 100%;
    height: 100%;
    padding: 4px 8px 4px 12px;
    color: $ts10;
    @extend %font-s;
    &::placeholder {
      color: $ts10;
    }
  }
}

//states

.i-stroke {
  > input,
  select,
  textarea {
    background: $ts11;
    border: 1px solid $ts2;
    &:focus {
      border: 1px solid $primary;
    }
  }
}
.i-stroke-ts3 {
  > input,
  select,
  textarea {
    background: $ts11;
    border: 1px solid $ts3;
    &:focus {
      border: 1px solid $primary;
    }
  }
}
.i-fill {
  > input,
  select,
  textarea {
    background: $ts1;
    border: none;
    &:focus {
      border: 1px solid $primary;
    }
  }
}
.i-disable {
  > input,
  select,
  textarea {
    background: $ts2;
    border: none;
  }
}
.i-focus {
  > input,
  select,
  textarea {
    border: 1px solid $primary;
  }
}
.i-success {
  > input,
  select,
  textarea {
    border: 1px solid $success;
  }
}
.i-wrong {
  > input,
  select,
  textarea {
    border: 1px solid $danger;
  }
}

//correcao temporaria
select {
  option {
    color: $ts3;
  }
}
