.box-notifications{
  >.notifications-container{
    display: flex;
    flex-direction: column;
    padding:24px;
    background:$ts1-2;
    border-radius: 8px;
    >.notification{
      display: flex;
      height: 64px;
      border-bottom:1px solid $ts1-3;
      padding: 8px 0px;
      >img{
        margin-bottom: auto;
        margin-right: 16px;
        cursor:pointer;
      }
      >div{
        display: flex;
        flex-direction: column;
        >:nth-child(1){
          @extend %font-m;
          color:$ts4;
          margin-bottom: 8px;
          cursor:pointer;
        }
        >:nth-child(2){
          @extend %font-xxs-bold;
          color:$ts4;
          margin-bottom: 8px;
        }
        >:nth-child(3){
          @extend %font-s;
          color:$ts10;
          visibility:collapse;
          opacity: 0;
        }
      }
      &.active{
        height: auto;
        >div{
          transition: 0.5s;
          >:nth-child(1){
            @extend %font-m;
            color:$ts10;
            margin-bottom: 8px;
          }
          >:nth-child(2){
            @extend %font-xxs-bold;
            color:$ts3;
            margin-bottom: 8px;
          }
          >:nth-child(3){
            @extend %font-s;
            color:$ts10;
            visibility:visible;
            opacity: 1;
          }
        }
      }
      &:last-child{
        border-bottom:0px;
      }
    }
  }
}
