//modal wallet pending and network pending
.box-modal-home {
  padding-bottom: 100px;

  .wallet-pending {
    display: flex;
    flex-direction: column;
    width: 464px;
    height: 414px;
    padding: 24px;
    @include background-opacity($ts2, 0.8);
    border: 1px solid;
    @include border-opacity($ts3, 0.3);
    @extend %align-horizontal;

    > div:nth-child(1) {
      margin-bottom: 20px;

      > p {
        text-align: center;
        @extend %font-l;
        color: $ts10;
      }
    }

    > div:nth-child(2) {
      display: flex;
      margin-bottom: 20px;

      > div:nth-child(1) {
        min-width: 136px;
        height: 136px;
        background: $ts1;
        border-radius: 70px;
        margin-right: 20px;

        > img {
          @extend %align-center;
        }
      }

      > div:nth-child(2) {
        width: 100%;

        > p:nth-child(1) {
          @extend %font-xs;
          text-align: center;
          color: $ts10;
          border: 1px solid $danger;
          border-radius: 2px;
          padding: 5px 0px;
          margin-bottom: 20px;
        }

        > p:nth-child(2) {
          @extend %font-s;
          color: $ts10;
          line-height: 20px;
        }
      }
    }

    > div:nth-child(3) {
      display: flex;
      flex-direction: column;

      > div {
        display: flex;

        > div:nth-child(1) {
          min-width: 104px;
          height: 72px;
          display: flex;
          margin-right: 12px;
          background: $ts3;

          > img {
            width: 40px;
            height: 40px;
            @extend %align-center;
          }
        }

        > div:nth-child(2) {
          position: relative;
          width: 100%;

          > p {
            @extend %font-xs;
            color: $ts10;

            &:first-child {
              margin-bottom: 4px;
            }
          }

          > button {
            position: absolute;
            bottom: 0;
            width: 100%;
          }
        }

        &:first-child {
          margin-bottom: 20px;
        }
      }
    }
  }

  .network-pending {
    display: flex;
    flex-direction: column;
    width: 464px;
    height: 414px;
    padding: 24px;
    @include background-opacity($ts2, 0.8);
    border: 1px solid;
    @include border-opacity($ts3, 0.3);
    @extend %align-horizontal;

    > div:nth-child(1) {
      margin-bottom: 20px;

      > p {
        text-align: center;
        @extend %font-l;
        color: $ts10;
      }
    }

    > div:nth-child(2) {
      display: flex;
      margin-bottom: 20px;

      > div:nth-child(1) {
        min-width: 136px;
        height: 136px;
        background: $ts1;
        border-radius: 70px;
        margin-right: 20px;

        > img {
          @extend %align-center;
        }
      }

      > div:nth-child(2) {
        width: 100%;

        > p:nth-child(1) {
          @extend %font-s;
          color: $ts10;
          line-height: 20px;
        }
      }
    }

    > div:nth-child(3) {
      display: flex;
      flex-direction: column;

      > div {
        display: flex;

        > div:nth-child(1) {
          width: 104px;
          height: 72px;
          display: flex;
          margin-right: 12px;
          background: $ts3;

          > img {
            width: 40px;
            height: 40px;
            @extend %align-center;
          }
        }

        > div:nth-child(2) {
          position: relative;

          > p {
            @extend %font-xs;
            color: $ts10;

            &:first-child {
              margin-bottom: 4px;
            }
          }

          > button {
            position: absolute;
            bottom: 0;
          }
        }

        &:first-child {
          margin-bottom: 20px;
        }
      }
    }
  }

  .pending-agreement {
    width: 467px;
    display: flex;
    flex-direction: column;
    @extend %align-horizontal;
    padding: 24px;
    @include background-opacity($ts2, 0.8);
    border: 1px solid;
    @include border-opacity($ts3, 0.3);

    > div:nth-of-type(1) {
      margin-bottom: 20px;

      p {
        @extend %font-l;
        text-align: center;
        color: $ts10;
      }
    }

    > div:nth-of-type(2) {
      height: 330px;
      padding: 14px 14px 0px 14px;
      margin-bottom: 20px;
      overflow-y: scroll;
      background: $ts3;
      border-radius: 2px;

      p {
        @extend %font-xs;
        color: $ts10;
        margin-bottom: 20px;
        line-height: 16px;

        &.item {
          @extend %font-xs-bold;
          color: $ts10;
          margin-bottom: 4px;
        }
      }

      /* width */
      &::-webkit-scrollbar {
        width: 10px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        @include background-opacity($ts1, 0.15);
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        @include background-opacity($ts2, 0.5);
        border-radius: 4px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }

    > div:nth-of-type(3) {
      label {
        @extend %font-xs;
        color: $ts10;
        cursor: pointer;

        > input {
          margin-right: 6px;
        }
      }

      button {
        width: 100%;
        margin-top: 20px;
      }
    }
  }

  .pending-debits {
    display: flex;
    flex-direction: column;
    width: 464px;
    height: auto;
    @include background-opacity($ts2, 0.8);
    border: 1px solid;
    @include border-opacity($ts3, 0.3);
    @extend %align-horizontal;
    .menu {
      padding: $padding-red;
      @include background-opacity($ts1, 0.8);
      p {
        @extend %font-m;
        color: $ts10;
      }
    }
    .debits {
      padding: $padding-red;
      > p {
        @extend %font-s;
        color: $ts10;
        padding-bottom: $padding-red;
      }
      .product {
        display: flex;
        justify-content: space-between;
        padding: $padding-orange;
        @include background-opacity($ts1, 0.5);
        margin-bottom: 1px;
        > div:nth-child(1) {
          display: flex;
          > div:nth-child(1) {
            height: 36px;
            width: 36px;
            border-radius: 50%;
            background: $ts10;
            margin-right: 10px;
            img {
              height: 36px;
              width: 36px;
            }
          }
          > div:nth-child(2) {
            p {
              @extend %font-m;
              color: $ts10;
              @extend %align-vertical;
            }
          }
        }
        > div:nth-child(2) {
          display: flex;
          > div {
            display: flex;
            flex-direction: column;
            width: 70px;
            &:last-child {
              width: 50px;
            }
            p {
              @extend %font-xxs;
              color: $ts10;
              line-height: 18px;
              &:first-child {
                font-weight: bold;
              }
              &.danger {
                color: $danger;
              }
            }
          }
        }
      }
    }
    .action-button {
      button {
        padding: 0px 30px;
        @extend %align-horizontal;
      }
    }
    .box-alert {
      padding: $padding-red;
      .alert {
        padding: $padding-blue;
        display: flex;
        align-items: center;
        @include background-opacity($ts1, 0.5);
        margin-bottom: 1px;
        img {
          width: 18px;
          height: 16px;
          //@extend %align-vertical;
          margin-right: 12px;
        }
        p {
          color: $ts10;
          @extend %font-xs;
          line-height: 16px;
        }
      }
    }
    .alert-suspended{
      background:$danger;
      padding:$padding-blue $padding-orange;
      p{
        @extend %font-m;
        line-height: 22px;
        color:$ts10;
        text-align: center;
        margin-bottom: 4px;
        &:last-child{
          @extend %font-xs;
          line-height: 16px;
          margin-bottom: 0px;
        }
      }
    }
    &.suspended{
      .action-button{
        margin-bottom: $margin-red;
      }
    }
    .off{
      display: none;
    }
  }
}
//modal de aceite de termos para compra de produto
.pending-agreement {
  width: 467px;
  display: flex;
  flex-direction: column;
  @extend %align-center;
  padding: 24px;
  @include background-opacity($ts2, 0.8);
  border: 1px solid;
  @include border-opacity($ts3, 0.3);

  > div:nth-of-type(1) {
    margin-bottom: 20px;

    p {
      @extend %font-l;
      text-align: center;
      color: $ts10;
    }
  }

  > div:nth-of-type(2) {
    height: 330px;
    padding: 14px 14px 0px 14px;
    margin-bottom: 20px;
    overflow-y: scroll;
    background: $ts3;
    border-radius: 2px;

    p {
      @extend %font-xs;
      color: $ts10;
      margin-bottom: 20px;
      line-height: 16px;

      &.item {
        @extend %font-xs-bold;
        color: $ts10;
        margin-bottom: 4px;
      }
    }

    /* width */
    &::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      @include background-opacity($ts1, 0.15);
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      @include background-opacity($ts2, 0.5);
      border-radius: 4px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  > div:nth-of-type(3) {
    label {
      @extend %font-xs;
      color: $ts10;
      cursor: pointer;

      > input {
        margin-right: 6px;
      }
    }

    button {
      width: 100%;
      margin-top: 20px;
    }
  }
}
//media box modal home
@media screen and (max-width: $breakpoint-width-xs) {
  .box-modal-home {
    padding: 0px 10px 100px 10px;
  }
}

//media modal wallet pending
@media screen and (max-width: $breakpoint-width-xs) {
  .box-modal-home {
    .wallet-pending {
      width: 100%;
    }
  }
}

@media screen and (max-width: $breakpoint-width-xxs) {
  .box-modal-home {
    .wallet-pending {
      height: auto;

      > div:nth-of-type(2) {
        flex-direction: column;

        > div:nth-of-type(1) {
          width: 100%;
          border-radius: 0px;
        }
      }

      > div:nth-of-type(3) {
        > div {
          flex-direction: column;

          > div:nth-of-type(1) {
            width: 100%;
            margin-bottom: 10px;
          }

          > div:nth-of-type(2) {
            > p:nth-of-type(2) {
              margin-bottom: 10px;
            }

            > button {
              position: relative;
              width: 100%;
            }
          }
        }
      }
    }
  }
}
//modal pendings debits

@media screen and (max-width: $breakpoint-width-xs) {
  .box-modal-home {
    .pending-debits {
      width: 100%;
      .debits {
        .product {
          flex-direction: column;
          justify-content: unset;
          > div:nth-child(1) {
            justify-content: center;
            margin-bottom: 20px;
            margin-top: 8px;
          }
          > div:nth-child(2) {
            > div {
              width: 100%;
              p {
                text-align: center;
              }
              &:last-child {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
