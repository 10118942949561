.box-swap{
  display:flex;
  >.wallets{
    display: flex;
    flex-direction: column;
    width:100%;
    margin-right: 48px;
    >.adress-container{
      display: flex;
      flex-direction: column;
      width: 460px;
      margin-bottom: 24px;
      >p{
        @extend %font-l;
        color:$ts10;
        margin-bottom: 16px;
      }
      >.adress{
        display: flex;
        flex-direction: row;
        position: relative;
        padding:24px;
        @include background-opacity($ts2, 0.2);
        border:1px solid;
        @include border-opacity($ts3,0.2);
        border-radius: 16px;
        >.qr-code{

          padding:8px;
          margin-right: 16px;
          @include background-opacity($ts20, 0.4);
          border-radius: 8px;
          >svg{
            height: 96px;
            width: 96px;
          }
        }
        >.content{
          display: flex;
          flex-direction: column;
          >div{
            display: flex;
            flex-direction: column;
            >p:nth-of-type(1){
              @extend %font-xs;
              color:$ts3;
              margin-bottom: 8px;
            }
            >p:nth-of-type(2){
              @extend %font-xs;
              color:$ts10;
              >img{
                margin-right: 8px;
              }
            }
            &:first-child{
              margin-bottom: 12px;
              cursor:pointer;
            }
          }
        }
        >.copy-tooltip{
          position: absolute;
          top:12px;
          right: 12px;
          background: $ts2;
          padding: 4px 10px;
          border-radius: 1px;
          border: 1px solid $success;

          p {
            @extend %font-xxs;
            color: $ts10;
          }
        }
      }
    }
    >.assets-container{
      display: flex;
      flex-direction: column;
      >p{
        @extend %font-l;
        color:$ts10;
        margin-bottom: 16px;
      }
      >.assets{
        display: flex;
        flex-direction: column;
        >div{
          display: flex;
          flex-direction: row;
          align-items:center;
          padding:12px 16px;
          @include background-opacity($ts20, 0.4);
          border:1px solid $ts2;
          border-radius: 8px;
          margin-bottom: 8px;
          >img{
            width:24px;
            object-fit: fill;
            margin-right: 8px;
          }
          >p{
            @extend %font-xs;
            color:$ts10;
            &:nth-of-type(1){
              width:134px;
            }
          }
          >button{
            margin-left: auto;
            border-radius: 4px;
            &:nth-of-type(2){
              margin-left: 10px;
            }
          }
          &:last-child{
            margin-bottom: 0px;
          }
        }
      }
    }
  }
  >.swap{
    display: flex;
    flex-direction: column;
    >.title{
      @extend %font-l;
      color:$ts10;
      margin-bottom: 16px;
    }
    >.swap-container{
      display: flex;
      flex-direction: column;
      padding:24px;
      width: 480px;
      @include background-opacity($ts2, 0.2);
      border:1px solid;
      @include border-opacity($ts3,0.2);
      border-radius: 16px;
      >.asset-swap{
        display: flex;
        flex-direction: column;
        >div:nth-of-type(1){
          display: flex;
          justify-content: space-between;
          margin-bottom: 8px;
          >p:nth-of-type(1){
            @extend %font-s-bold;
            color:$ts10;
          }
          >p:nth-of-type(2){
            @extend %font-xs;
            color:$ts10;
          }
        }
        >div:nth-of-type(2){
          display: flex;
          align-items:center;
          justify-content: space-between;
          background:$ts2;
          border-radius: 8px;
          padding:8px 12px;
          >div:nth-of-type(1){
            display: flex;
            align-items: center;
            cursor:pointer;
            >img:nth-of-type(1){
              width:24px;
              height: 24px;
              margin-right: 8px;
            }
            >p{
              @extend %font-xs;
              color:$ts10;
              margin-right: 4px;
            }
            >img:nth-of-type(2){
              width:16px;
              height: 16px;
            }
          }
          >input:nth-of-type(1){
            text-align: right;
            background:transparent;
            border:0px;
            color:$ts10;
            margin-left: auto;
            &::placeholder{
              color:$ts10;
            }
          }
          >p:nth-of-type(1){
            @extend %font-xxs;
            color:$primary;
            margin-left: 14px;
            cursor: pointer;
          }
        }
      }
      >.change-swap{
        display: flex;
        justify-content: center;
        >div{
          display: flex;
          justify-content: center;
          align-items: center;
          height: 36px;
          width: 36px;
          border-radius: 50%;
          background:$ts2;
          margin:20px 0px 4px 0px;
          cursor:pointer;
          >img{
            height: 24px;
            width: 24px;
          }
        }
      }
      >.estimate-price{
        display: flex;
        justify-content: space-between;
        margin: 0px;
        margin-top: 16px;
        >p{
          @extend %font-xs;
          color:$ts10;
          &.is-warning{
            color: $warning;
          }
        }
      }
      >.estimate-fee{
        display: flex;
        justify-content: space-between;
        margin: 0px;
        margin-bottom: 16px;
        >p{
          @extend %font-xs;
          color:$ts10;
          &.is-warning{
            color: $warning;
          }
        }
      }
      >button{
        border-radius: 8px;
      }
    }
  }
}
@media screen and (max-width: $breakpoint-width-xl) {
  .box-swap{
    flex-direction: column;
    padding:16px;
    >.wallets{
      margin-bottom: 24px;
      margin-right: 0px;
      >.adress-container{
        width:100%;
      }
    }
    >.swap{
      margin-bottom: 24px;
      >.swap-container{
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: $breakpoint-width-xs) {
  body.swap{
    .container{
      padding:140px 20px 0px 20px;
      .adress-container{
        >.adress{
          flex-direction: column;
          >.qr-code{
            display:flex;
            justify-content:center;
            margin-bottom: 14px;
          }
          >.content{
            >div{
              >p:nth-of-type(2){
                word-break: break-all;
              }
            }
          }
        }
      }
      .assets-container{
        >.assets{
          >div{
            flex-direction: column;
            align-items: center;
            >img{
              margin-bottom: 8px;
            }
            >button{
              margin-left: 0px;
            }
            >button:nth-of-type(2){
              margin-left: 0px;
            }
            >p{
              text-align: center;
              margin-bottom: 8px;
            }
          }
        }
      }
      .swap-container{
        >.asset-swap{
          >div:nth-of-type(2){
            >input{
              width: 100%;
            }
          }
        }
      }
    }
  }
  body.swap .container .assets-container > .assets > div > button{

      margin-bottom: 8px;

  }
}
