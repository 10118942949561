//Colors base
$ts1: #0e121c;
$ts1-1: #111520;
$ts1-2: #171C29;
$ts1-3: #232938;
$ts2: #1e2332;
$ts3: #5d6579;
$ts4:#878D9E;
$ts10: #fdfdfd;
$ts11: transparent;
$ts20:#0B0E16;
$ts21:#0B0E16;
//Colors
$success: #27ae60;
$danger: #ff4f4f;
$info: #faa61a;
$primary: #036cbc;
$warning: #f6891e;
$profitfee: #df9e52;


//Breakpoints

$breakpoint-width-xxs: 400px;
$breakpoint-width-xs: 500px;
$breakpoint-width-s: 600px;
$breakpoint-width-m: 820px;
$breakpoint-width-l: 1000px;
$breakpoint-width-xl: 1080px;
$breakpoint-width-xxl: 1281px;

//Metrics
$padding-red: 20px;
$padding-blue: 16px;
$padding-orange: 12px;
$padding-pink: 8px;
$padding-green: 6px;

$margin-red: 20px;
$margin-blue: 16px;
$margin-orange: 12px;
$margin-pink: 8px;
$margin-green: 6px;
