.box-support {
  display: flex;
  flex-direction: column;
  .form-support {
    display: flex;
    flex-direction: column;
    padding: 32px;
    background: $ts1-1;
    border:1px solid $ts1-3;
    border-radius: 16px;
    margin-bottom: 32px;
    > p:nth-of-type(1) {
      @extend %font-m;
      color: $ts10;
      margin-bottom: 18px;
    }
    > div:nth-of-type(1) {
      display: flex;
      background:$ts20;
      padding:16px;
      border-radius: 8px;
      margin-bottom: 18px;
      p {
        @extend %font-s;
        color: $ts10;
        margin-right: 16px;
      }
    }
    > div:nth-of-type(2) {
      display: flex;
      flex-direction: column;
      > div {
        margin-bottom: 12px;
        height: 40px;
        select,input,textarea {
          border-radius: 8px;
        }
        &:nth-child(3) {
          height: 72px;
        }
      }
    }
  }
  .tickets-history {
    display:flex;
    flex-direction: column;
    height: auto;
    width: 100%;
    padding: 32px;
    background: $ts1-1;
    border:1px solid $ts1-3;
    border-radius: 16px;
    > div:nth-child(1) {
      padding: 12px;
      display: flex;
      justify-content: space-between;
      .title {
        p {
          @extend %font-l;
          color: $ts10;
        }
      }

      .filter {
        display: flex;

        > div:nth-child(1) {
          width: 152px;
          margin-right: 12px;

          > div {
            select {
              width: 100%;
              height: 100%;
              color: $ts10;
              @extend %font-xs;

              border-radius: 0px;
            }
          }
        }

        > div:nth-child(2) {
          display: flex;
          margin-right: 8px;

          > p {
            color: $ts10;
            @extend %font-s;
            line-height: 27px;
            margin-right: 4px;
          }

          input {
            border-radius: 4px;

            &::-webkit-calendar-picker-indicator {
              &:hover {
                background-color: transparent;
                cursor: pointer;
              }
            }

            &::-webkit-clear-button {
              display: none;
            }

            &::-webkit-inner-spin-button {
              display: none;
            }
          }
        }

        > div:nth-child(3) {
          display: flex;

          > p {
            color: $ts10;
            @extend %font-s;
            line-height: 27px;
            margin-right: 4px;
          }

          input {
            border-radius: 4px;

            &::-webkit-calendar-picker-indicator {
              &:hover {
                background-color: transparent;
                cursor: pointer;
              }
            }

            &::-webkit-clear-button {
              display: none;
            }

            &::-webkit-inner-spin-button {
              display: none;
            }
          }
        }
      }
    }

    > div:nth-child(2) {
      > table {
        tr {
          border-bottom: 1px solid $ts2;
          width: 100%;

          th {
            text-align: left;
            color: $ts3;
            @extend %font-s-bold;
            padding: 8px 0px;

            &:nth-child(1) {
              width: 134px;
              padding-left: 12px;
            }

            &:nth-child(2) {
              width: 120px;
            }

            &:nth-child(3) {
              width: 120px;
            }

            &:nth-child(4) {
              width: 484px;
            }

            &:nth-child(5) {
              width: 80px;
            }

            &:nth-child(6) {
              width: 80px;
            }
          }

          td {
            text-align: left;
            color: $ts10;
            @extend %font-xs;
            padding: 12px 0px;

            &:nth-child(1) {
              padding-left: 12px;
            }
            &:nth-child(6) {
              a {
                @extend %font-link;
              }
            }
          }
        }
      }
    }
  }
}

@include screenMaxWidth(600px){
  .box-support .form-support > div:nth-of-type(1){
    flex-direction: column;
    >p{
      margin-bottom: 4px;
      &:last-child{
        margin-bottom: 0px;
      }
    }
  }
}
@media screen and (max-width: $breakpoint-width-m) {
  .box-support {
    .tickets-history {
      height: auto;
      width: 100%;
      overflow-x: auto;

      > div {
        min-width: 780px;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-width-xxs) {
  .box-support {
    .form-support {
      > div:nth-of-type(1) {
        .separator:nth-of-type(2) {
          display: none;
        }
      }
    }
  }
}
