.modal-withdraw{
  display:flex;
  flex-direction: column;
  padding:24px;
  width:464px;
  @include background-opacity($ts2, 0.8);
  border:1px solid;
  @include border-opacity($ts3, 0.3);
  border-radius: 4px;
  @extend %align-horizontal;
  >.title{
    display:flex;
    margin-bottom: 24px;
    >img{
      margin-right: 8px;
      width:24px;
      object-fit:fill;
    }
    >p{
      @extend %font-l;
      color:$ts10;
    }
  }
  >.input-box{
    display:flex;
    flex-direction: column;
    margin-bottom: 14px;
    >p:nth-of-type(1){
      @extend %font-s-bold;
      color:$ts10;
      margin-bottom: 8px;
    }
    >input{
      background:$ts1;
      padding:8px 16px;
      color:$ts10;
      @extend %font-xs;
      border:0px;
      border-radius: 4px;
      &::placeholder{
        @include color-opacity($ts10, 0.5);
      }
    }
    &.disabled{

      >input{
        color:$ts10;
        background:$ts2;
        &::placeholder{
          color: $ts10;
        }
      }
    }
    >.error{
      @extend %font-xxs;
      color:$danger;
      margin-top: 8px;
    }
    &.amount{
      margin-bottom: 20px;
      >div:nth-of-type(1){
        position: relative;
        margin-bottom: 8px;
        >input{
          width: 100%;
          background:$ts1;
          padding:8px 16px;
          color:$ts10;
          @extend %font-xs;
          border:0px;
          border-radius: 4px;
          &::placeholder{
            @include color-opacity($ts10, 0.5);
          }
        }
        >p{
          @extend %font-xs;
          position: absolute;
          color:$primary;
          text-transform: uppercase;
          right:16px;
          top:12px;
          cursor: pointer;
        }
      }
      >p:nth-of-type(2){
        @extend %font-xs;
        color:$ts10;
      }
    }
  }
  >.alert{
    display:flex;
    align-items:center;
    margin-bottom: 20px;
    >img{
      max-width: 24px;
      margin-right: 8px;
    }
    >p{
      @extend %font-xs;
      color:$danger;
    }
  }
  >.amount-received{
    display:flex;
    flex-direction: column;
    background:$ts2;
    border-radius: 4px;
    padding:16px;
    margin-bottom: 12px;
    >p{
      color:$ts10;
      &.danger {
        color:$danger;
      }
    }
    >p:nth-of-type(1){
      @extend %font-s-bold;
      margin-bottom: 10px;
    }
    >p:nth-of-type(2){
      @extend %font-l;
      margin-bottom: 10px;
    }
    >p:nth-of-type(3){
      @extend %font-xs;
    }
  }
  >.confirm-term{
    display:flex;
    align-items:center;
    margin-bottom: 22px;
    >input{
      margin-right: 8px;
    }
    >label{
      @extend %font-xs;
      color:$ts10;
      cursor: pointer;
    }
  }
  >.button-action{
    display: flex;
    justify-content:center;
    >button{
      min-width: 140px;
    }
    >button:first-child{
      margin-right: 20px;
      cursor: pointer;
    }
  }

}

.modal-confirm-withdraw{
  display:flex;
  flex-direction: column;
  padding:24px;
  width:464px;
  @include background-opacity($ts2, 0.8);
  border:1px solid;
  @include border-opacity($ts3, 0.3);
  border-radius: 4px;
  @extend %align-horizontal;
  >.title{
    display:flex;
    margin-bottom: 24px;
    >img{
      margin-right: 8px;
      width:24px;
      object-fit:fill;
    }
    >p{
      @extend %font-l;
      color:$ts10;
    }
  }
  >.subtitle{
    display:flex;
    align-items: center;
    margin-bottom: 8px;
    >img{
      width:61px;
      object-fit:fill;
      margin-right: 16px;
    }
    >p{
      @extend %font-xs;
      color:$ts10;
    }
  }
  >.content{
    display:flex;
    flex-direction: column;
    background:$ts2;
    border-radius: 4px;
    padding:20px;
    margin-bottom: 20px;
    >div{
      >p{
        color:$ts10;
      }
      >p:nth-of-type(1){
        @extend %font-xs-bold;
        margin-bottom: 8px;
      }
      >p:nth-of-type(2){
        @extend %font-s;
        margin-bottom: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &:last-child{
        >p:nth-of-type(2){
          margin-bottom: 0px;
        }
      }
    }
  }
  >.input-box{
    display:flex;
    flex-direction: column;
    margin-bottom: 14px;
    >p:nth-of-type(1){
      @extend %font-s-bold;
      color:$ts10;
      margin-bottom: 8px;
    }
    >input{
      background:$ts1;
      padding:8px 16px;
      color:$ts10;
      @extend %font-xs;
      border:0px;
      border-radius: 4px;
      &::placeholder{
        @include color-opacity($ts10, 0.5);
      }
    }
  }
  >.button-action{
    display:flex;
    justify-content:center;
    >button{
      min-width:116px;
      &:first-child{
        margin-right: 16px;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-width-xs) {
  .modal-withdraw,.modal-confirm-withdraw{
    width:100%;
  }
}

@media screen and (max-width: $breakpoint-width-xxs) {
  .modal-withdraw,.modal-confirm-withdraw{
    >.button-action{
      flex-direction: column;
      >button:first-child{
        margin-right: initial;
        margin-bottom: 12px;
      }
    }
  }
}
