.table{
    display: flex;
    flex-direction: column;
    background: $ts1-2;
    padding:24px;
    border-radius: 8px;
    >.options{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 32px;
      >.left-side{
        display: flex;
        >.select-buttons{
          display: flex;
          >div{
            display: flex;
            padding:12px 24px;
            background:$ts1-3;
            border-radius: 4px;
            margin-right: 8px;
            cursor: pointer;
            p{
              @extend %font-s;
              color:$ts3;
            }
            &:last-child{
              margin-right: 0px;
            }
            &.active{
              background:$primary;
              p{
                color:$ts10;
              }
            }
          }
        }

      }
      >.right-side{
        display: flex;
        align-items: center;
        >.select{
          >label{
            @extend %font-s;
            color:$ts10;
            margin-right: 16px;
          }
          >select{
            @extend %font-s;
            background: transparent;
            border:1px solid $ts3;
            color:$ts10;
            border-radius:4px;
            padding:4px 14px 4px 4px;
          }
        }
        >.date{
          display: flex;
          >div{
            display: flex;
            >p{
              @extend %font-s;
              color:$ts10;
              line-height: 32px;
              margin-right: 8px;
            }
            >input{
              width:140px;
              height: 32px;
              border-radius: 4px;
              background:transparent;
              border:1px solid $ts3;
              color:$ts10;
              @extend %font-s;
              padding-left: 8px;
              &::-webkit-calendar-picker-indicator {
                filter: invert(1);
                cursor:pointer;
              }
              &:nth-of-type(1){
                margin-right: 16px;
              }
            }
          }
        }
      }
    }

  }

  @include screenMaxWidth(950px){
      .table{
       >.options{
         flex-direction: column;
         >.left-side {
           margin-bottom: 16px;
         }
       }
      }
  }

  @include screenMaxWidth(740px){
      .table{
       >.options{
         flex-direction: column;
         >.left-side {
           >.select-buttons{
             >div{
               padding:12px;
             }
           }
         }
         >.right-side{
          flex-direction: column;
          align-items: inherit;
          >.select{
            margin-bottom: 8px;
          }
          >.date{
             flex-direction: column;
             >div:first-child{
               margin-bottom: 8px;
             }
           }
         }
       }
      }

  }

