footer {
  height: 100px;
  width: 100%;
  position: fixed;
  bottom: 0px;
  // background: -moz-linear-gradient(top, rgba(125, 126, 125, 0) 0%, rgba(14, 14, 14, 1) 100%);
  // background: -webkit-linear-gradient(top, rgba(125, 126, 125, 0) 0%, rgba(14, 14, 14, 1) 100%);
  // background: linear-gradient(to bottom, rgba(125, 126, 125, 0) 0%, rgba(14, 14, 14, 1) 100%);
  // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#007d7e7d', endColorstr='#0e0e0e', GradientType=0);
  .box-footer {
    display: flex;
    align-items: flex-end;
    width: auto;
    position: absolute;
    bottom: 8px;
    @extend %align-horizontal;

    > a {
      > div {
        display: flex;
        position: relative;
        flex-direction: column;
        padding:8px 0px;
        width: 72px;
        background-color: $ts20;
        border-radius:8px;
        margin-right: 4px;
        transition:0.5s;
        border:1px solid $ts20;
        > svg {
          height: 24px;
          width: 24px;
          margin-bottom: 4px;
          @extend %align-horizontal;
          path {
            fill: $ts3;
            transition: 0.5s;
          }
        }

        > p {
          @extend %font-s;
          text-align: center;
          color:$ts3;
          text-decoration: none;
        }
        .badge-menu {
          position: absolute;
          left: 8px;
        }

      }
      &.active {
        >div{
          padding:14px 0px;
          transition: 0.5s;
          border:1px solid;
          @include border-opacity($info, 0.2);
          >p{
            color:$info;
          }
        }
        svg {
          path {
            fill: $info;
          }
        }
      }
      &.disabled {
        > div {
          cursor: default;
        }
        svg {
          circle {
            fill: $ts11;
            stroke: $ts3;
          }
          path {
            fill: $ts3;
          }
        }
        p {
          color: $ts3;
        }
      }
      &:last-child{
        >div{
          margin-right: 0px;
        }
      }
    }
  }
  .menu-mobile {
    visibility: collapse;
    opacity: 0;
    transition: 0.5s;
  }
  .box-footer-mobile {
    visibility: collapse;
  }
}
//ajuste de footer na versao ru
.ru-RU {
  > #root {
    footer {
      > .box-footer {
        > a {
          > div {
            width: 90px;
          }
        }
      }
    }
  }
}

//Break M
@media screen and (max-width: $breakpoint-width-m) {
  footer {
    z-index: 500;
    .box-footer {
      display: none;
    }

    .box-footer-mobile {
      width: 100%;
      position: absolute;
      bottom: 0px;
      height: 60px;
      border-top: 1px solid;
      z-index: 501;
      @include border-opacity($ts3, 0.2);
      @include background-opacity($ts1, 0);
      visibility: visible;
      transition: 0.5s;
      > .selector {
        width: 60px;
        height: 70px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        top: -20px;
        display: flex;
        flex-direction: column;
        cursor: pointer;

        > div:nth-of-type(1) {
          height: 40px;
          width: 40px;
          border-radius: 20px;
          @include background-opacity($ts1, 1);
          border: 1px solid;
          @include border-opacity($ts3, 0.2);
          left: 50%;
          position: relative;
          transform: translateX(-50%);

          > img {
            position: relative;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }

        > p:nth-of-type(1) {
          font-size: 12px;
          font-weight: 300;
          color: $ts10;
          text-align: center;
          margin-top: 10px;
        }
      }
    }

    .menu-mobile {
      position: absolute;
      width: 100%;
      max-height: 90vh;
      @include background-opacity($ts1, 0.95);
      border-top: 1px solid;
      @include border-opacity($ts3, 0.2);
      padding: 20px 15px 20px 15px;
      bottom: 0px;
      overflow-y: scroll;
      z-index: 500;
      > div {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 90px;
        > a {
          width: 32.6%;
          height: 121px;
          @include background-opacity($ts1, 0.9);
          border: 1px solid $ts2;
          margin-bottom: 4px;
          margin-right: 3px;
          > div {
            height: 100%;
            width: 100%;
            > div {
              width: 68px;
              display: flex;
              flex-direction: column;
              position: relative;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);

              svg {
                width: 28px;
                height: 28px;
                position: relative;
                left: 50%;
                transform: translateX(-50%);
                margin-bottom: 4px;

                path {
                  fill: $ts10;
                  transition: 0.5s;
                }
              }

              p {
                font-size: 14px;
                font-weight: 300;
                color: $ts10;
                text-align: center;
              }

              .badge-menu {
                position: absolute;
                left: 0px;
              }
            }
          }
          &.active {
            >div{
              svg {
                path {
                  fill: $info;
                }
              }
              p{
                color:$info;
              }
            }
          }
          &.disabled {
            > div {
              cursor: default;
            }
            svg {
              circle {
                fill: $ts11;
                stroke: $ts3;
              }
              path {
                fill: $ts3;
              }
            }
            p {
              color: $ts3;
            }
          }
          &:nth-child(3n+3){
            margin-right: 0px;
          }
        }
      }
    }
  }

  .menu-mobile-open {
    overflow: hidden;
    overflow-y: hidden;

    .container {
      overflow-y: hidden;
    }

    footer {
      .box-footer-mobile {
        @include background-opacity($ts1, 1);
      }

      .menu-mobile {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
