//modal overview product
.box-overview-product {
  width: 800px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  margin: 20px 0px;
  @include background-opacity($ts2, 1);
  @extend %align-horizontal;
  .menu {
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;
    > img:nth-child(1) {
      height: 34px;
    }
    > img:nth-child(2) {
      height: 15px;
      width: 15px;
      margin-top: 6px;
      opacity: 0.5;
      cursor: pointer;
    }
  }
  .info {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;
    .info-main {
      width: 480px;
      display: flex;
      flex-direction: column;
      margin-right: 20px;
      > img {
        width: 100%;
      }
      .details {
        height: 100%;
        padding: 12px;
        @include background-opacity($ts1, 0.5);
        > p {
          @extend %font-m;
          color: $ts3;
          margin-bottom: 12px;
        }
        .cards-details {
          display: flex;
          margin-bottom: 24px;
          > div {
            width: 150px;
            height: 68px;
            padding: 8px;
            @include background-opacity($ts1, 0.5);
            border: 1px solid;
            @include border-opacity($ts3, 0.2);
            margin-right: 3px;
            > p {
              @extend %font-xs;
              color: $ts3;
              text-align: center;
              &:first-child {
                margin-bottom: 8px;
              }
            }
            &:last-child {
              margin-right: 0px;
            }
          }
          .profit {
            p:nth-child(2) {
              @extend %font-l-bold;
              color: $success;
            }
          }
          .risk {
            p:nth-child(2) {
              @extend %font-m;
              color: $warning;
            }
          }
          .investment {
            p:nth-child(2) {
              @extend %font-l-bold;
              color: $ts10;
            }
          }
        }
        .description {
          p:nth-child(1) {
            color: $ts3;
            @extend %font-m;
            margin-bottom: 12px;
          }
          p {
            color: $ts10;
            @extend %font-s;
            margin-bottom: 12px;
            line-height: 20px;
          }
        }
        .requirements {
          p:nth-child(1) {
            color: $ts3;
            @extend %font-m;
            margin-bottom: 12px;
          }
          ul {
            list-style: circle;
            margin-left: 19px;
            li {
              @extend %font-s;
              color: $ts10;
              line-height: 20px;
            }
          }
        }
      }
    }
    .info-sidebar {
      width: 260px;
      display: flex;
      flex-direction: column;
      position: relative;
      > a {
        height: 36px;
        width: 100%;
        background: $success;
        border-radius: 2px;
        @extend %font-m-bold;
        color: $ts10;
        text-align: center;
        line-height: 36px;
        cursor: pointer;
        margin-bottom: 8px;
      }
      .signature {
        display: flex;
        flex-direction: column;
        width: 100%;
        @include background-opacity($ts1, 0.5);
        border-radius: 2px;
        margin-bottom: 16px;
        .off {
          display: none;
        }
        .status-pre-purchase {
          display: flex;
          justify-content: space-between;
          padding: $padding-blue;
          > div:nth-child(1) {
            p {
              @extend %font-xs;
              color: $ts10;
              line-height: 16px;
            }
          }
          > div:nth-child(2) {
            p {
              @extend %font-l;
              color: $ts10;
              line-height: 32px;
            }
          }
        }
        .status-active {
          display: flex;
          flex-direction: column;
          padding: $padding-blue;
          p {
            @extend %font-xs;
            color: $ts10;
            text-align: center;
            line-height: 16px;
            &:first-child {
              margin-bottom: $margin-blue;
            }
          }
          > div {
            height: 10px;
            border-radius: 5px;
            background: $ts1;
            margin-bottom: $margin-pink;
            padding: 3px;
            > div {
              height: 100%;
              border-radius: 5px;
              background: $success;
              width: 10%;
              transition: 1s;
            }
          }
        }
        .status-renovation {
          display: flex;
          flex-direction: column;
          padding: $padding-blue;
          p {
            color: $ts10;
            @extend %font-xs;
            text-align: center;
            margin-bottom: $margin-blue;
          }
          a {
            @extend %font-xs;
            @extend %font-link-danger;
            text-align: center;
          }
        }
        .status-pending-payment {
          display: flex;
          flex-direction: column;
          padding: $padding-blue;
          p:nth-of-type(1) {
            @extend %font-xs-bold;
            color: $danger;
            text-transform: uppercase;
            text-align: center;
            margin-bottom: $margin-pink;
          }
          p:nth-of-type(n + 2) {
            @extend %font-xxs;
            color: $ts10;
            text-align: center;
          }
          p:nth-of-type(2) {
            margin-bottom: $margin-pink;
          }
          > div {
            height: 10px;
            border-radius: 5px;
            background: $ts1;
            margin-bottom: $margin-pink;
            padding: 3px;
            > div {
              height: 100%;
              border-radius: 5px;
              background: $danger;
              width: 10%;
              transition: 1s;
            }
          }
        }
        .profit-fee-bar {
          display: flex;
          justify-content: space-between;
          padding: $padding-pink $padding-blue;
          @include background-opacity($ts1, 0.9);
          > div:nth-child(1) {
            display: flex;
            > img {
              width: 28px;
              height: 29px;
              margin-right: 4px;
              margin-top: 1px;
            }
            > div {
              display: flex;
              flex-direction: column;
              margin-top: 2px;
              p:nth-child(1) {
                color: $profitfee;
                @extend %font-xs-bold;
              }
              p:nth-child(2) {
                color: $profitfee;
                @extend %font-xs;
              }
            }
          }
          > div:nth-child(2) {
            a {
              @extend %font-xs;
              @include color-opacity($ts10, 0.5);
              border: 1px solid;
              @include border-opacity($ts10, 0.5);
              border-radius: 2px;
              padding: 3px 8px;
              line-height: 30px;
              cursor: pointer;
            }
          }
        }
        .profit-fee-description {
          padding: $padding-blue;
          p {
            @extend %font-xxs;
            color: $ts10;
            line-height: 16px;
            margin-bottom: $padding-blue;
          }
          a {
            @extend %font-xxs;
            @extend %font-link;
          }
        }
        .profit-fee-details {
          display: flex;
          flex-direction: column;
          padding: $padding-blue;
          > div {
            display: flex;
            justify-content: space-between;
            &:last-of-type {
              margin-bottom: $margin-blue;
            }
            p {
              @extend %font-xxs;
              color: $ts10;
              line-height: 18px;
              &.success {
                color: $success;
              }
              &.danger {
                color: $danger;
              }
            }
          }
          p {
            @extend %font-xxs;
            color: $ts10;
            line-height: 16px;
            text-align: center;
          }
        }
        .license {
          display: flex;
          flex-direction: column;
          border-top: 1px solid;
          @include border-opacity($ts3, 0.2);
          padding: $padding-blue;
          > p {
            @extend %font-xs;
            color: $ts3;
            text-align: center;
            margin-bottom: $margin-blue;
          }
          > div {
            display: flex;
            justify-content: space-between;
            p {
              @extend %font-xxs;
              line-height: 18px;
              color: $ts10;
              &.danger {
                color: $danger;
              }
            }
          }
        }
        .alert {
          padding: $padding-blue;
          display: flex;
          align-items: center;
          background: $ts3;
          img {
            width: 12px;
            height: 10px;
            //@extend %align-vertical;
            margin-right: 12px;
          }
          p {
            color: $ts10;
            @extend %font-xxs;
            line-height: 16px;
          }
        }
        .button-action {
          display: flex;
          justify-content: center;
          padding: $padding-blue;
          border-top: 1px solid;
          @include border-opacity($ts3, 0.2);
          button {
            padding: 0px 30px;
          }
          a {
            @extend %font-xs;
            color: $ts3;
            &.danger {
              @extend %font-link-danger;
            }
            &.success {
              @extend %font-link-success;
            }
          }
        }
        // > div {
        //   @include background-opacity($ts1, 0.75);
        //   margin-bottom: 1px;
        //   > p {
        //     text-align: center;
        //   }
        // }
        // > div:nth-child(1) {
        //   height: 84px;
        //   padding: 12px 0px;
        //   display: flex;
        //   flex-direction: column;
        //   p:nth-child(1) {
        //     @extend %font-xs;
        //     color: $ts3;
        //     margin-bottom: 8px;
        //   }
        //   p:nth-child(2) {
        //     @extend %font-l-bold;
        //     font-size: 30px;
        //     color: $ts10;
        //   }
        //   p:nth-child(3) {
        //     @extend %font-xs;
        //     color: $ts10;
        //   }
        // }
        // > div:nth-child(2) {
        //   height: 52px;
        //   padding: 12px 0px;
        //   > button {
        //     @extend %align-horizontal;
        //   }
        // }
        // > div:nth-child(3) {
        //   height: 90px;
        //   padding: 12px 0px;
        //   p:nth-child(1) {
        //     @extend %font-xs;
        //     color: $ts3;
        //     margin-bottom: 8px;
        //   }
        //   > div {
        //     p {
        //       text-align: center;
        //       line-height: 40px;
        //     }
        //   }
        // }
      }
      .details {
        .title {
          @extend %font-m;
        }
        p {
          @extend %font-xs;
          margin-bottom: 12px;
          color: $ts10;
          line-height: 20px;
          &:last-of-type {
            margin-bottom: 16px;
          }
        }
      }
      .presentation {
        > p {
          @extend %font-m;
          color: $ts10;
          margin-bottom: 12px;
        }
        > img {
          width: 100%;
        }
      }
    }
  }
  .box-tutorials-product {
    display: flex;
    flex-direction: column;
    > p {
      @extend %font-m;
      color: $ts10;
      margin-bottom: 12px;
    }
    > .tutorials-products {
      display: flex;
      flex-wrap: wrap;

      .item {
        width: 236px;
        margin-right: 24px;
        position: relative;
        &:nth-child(3n) {
          margin-right: 0px;
        }
        > div:nth-child(1) {
          height: 148px;
          background-size: cover !important;
          background-position: center;
          > div {
            display: flex;
            flex-direction: column;
            width: 130px;
            @extend %align-center;
            cursor: pointer;
            background-size: cover;

            > img {
              width: 40px;
              height: 40px;
              margin-bottom: 12px;
              @extend %align-horizontal;
            }

            > p {
              @extend %font-xs-bold;
              color: $ts10;
              text-align: center;
            }
          }
        }

        > div:nth-child(2) {
          padding: 12px 16px;
          background: $ts1;
          min-height: 220px;

          > div:nth-child(1) {
            margin-bottom: 12px;

            > p {
              @extend %font-m-bold;
              color: $ts10;
              line-height: 22px;
            }
          }

          > div:nth-child(2) {
            margin-bottom: 12px;
            min-height: 80px;
            > p {
              color: $ts10;
              @extend %font-s;
              line-height: 20px;
            }
          }
          > div:nth-child(3) {
            position: absolute;
            bottom: 12px;
            > p {
              cursor: pointer;
              color: $primary;

              > img {
                margin-bottom: 4px;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: $breakpoint-width-m) {
  .box-overview-product {
    width: 100%;
    .info {
      flex-direction: column;
      .info-main {
        width: 100%;
        margin-bottom: 20px;
        .details {
          .cards-details {
            > div {
              width: 33.3%;
            }
          }
        }
      }
      .info-sidebar {
        width: 100%;
        .signature {
          margin-bottom: 20px;
        }
        .details {
          margin-bottom: 20px;
        }
        .presentation {
          position: relative;
        }
      }
    }
    .box-tutorials-product {
      .tutorials-products {
        justify-content: center;
        .item {
          margin-bottom: 12px;
        }
      }
    }
  }
}
@media screen and (max-width: $breakpoint-width-xs) {
  .box-overview-product {
    padding: 20px 10px;
    .info {
      .info-main {
        .details {
          .cards-details {
            flex-direction: column;
            > div {
              width: 100%;
              margin-bottom: 2px;
            }
          }
        }
      }
    }
    .box-tutorials-product {
      .tutorials-products {
        .item {
          width: 100%;
          margin-right: 0px;
        }
      }
    }
  }
}
