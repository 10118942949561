.box-signup-end {
  display: flex;
  justify-content:center;
  align-items:center;
  height: 100vh;
  padding:20px;
    > div:nth-child(1) {
      display: flex;
      flex-direction: column;
      border-radius:16px;
      height: auto;
      width:100%;
      padding:30px;
      max-width: 400px;
      background: $ts10;
      > div:nth-child(1) {
        margin-bottom: 40px;
        >img{
          width:100%;
          max-width: 250px;
        }
      }
      > div:nth-child(2) {
        margin-bottom: 40px;
        > p:nth-child(1) {
          @extend %font-l;
          color: $ts2;
          margin-bottom: 40px;
        }
        > div:nth-child(2) {
          display: flex;
          > img {
            width: 28px;
            height: 28px;
            margin-right: 8px;
          }
          > p {
            @extend %font-sm;
            color: $ts2;
          }
        }
      }
      > div:nth-child(3) {
        display: flex;
        flex-direction: column;
        button {
          width: 100%;
          margin-bottom: 40px;
        }
        a {
          @extend %font-s;
          @extend %font-link;
        }
      }
    }

}

.box-signup-resend {
  display: flex;
  justify-content:center;
  align-items:center;
  height: 100vh;
  padding:20px;
  >div:nth-child(1) {
    display: flex;
    flex-direction: column;
    border-radius:16px;
    height: auto;
    width:100%;
    padding:30px;
    max-width: 400px;
    background: $ts10;
      > div:nth-child(1) {
        margin-bottom: 40px;
        >img{
          width:100%;
          max-width: 250px;
        }
      }
      > div:nth-child(2) {
        display: flex;
        flex-direction: column;
        > p:nth-of-type(1) {
          @extend %font-l;
          color: $ts2;
          margin-bottom: 40px;
        }
        > p:nth-of-type(2) {
          @extend %font-sm;
          color: $ts2;
          margin-bottom: 16px;
        }
        > div:nth-of-type(1) {
          margin-bottom: 16px;
          input {
            color: $ts3;
            @extend %font-s;
            &::placeholder {
              color: $ts3;
            }
          }
        }
        > p:nth-of-type(3) {
          @extend %font-sm;
          margin-bottom: 20px;
        }
        > button:nth-of-type(1) {
          margin-bottom: 16px;
        }
        > a:nth-of-type(1) {
          @extend %font-sm;
          @extend %font-link;
          text-align: center;
        }
      }
  }

}
//Media queries
@media screen and (max-width: 400px) {
  .box-signup-end{
    >:nth-child(1){
      >:nth-child(1){
        >img{
          width:100%;
        }
      }
    }
  }
}
@media screen and (max-height: 380px) {
  .box-signup-end{
    align-items: initial;
    >:nth-child(1){
      height: auto;
      overflow-y:auto;
    }
  }
}
@media screen and (max-height: 510px) {
  .box-signup-resend{
    align-items: initial;
    >:nth-child(1){
      height: auto;
      overflow-y:auto;
    }
  }
}
