.marketing-container{
  display:flex;
  flex-direction: column;
  background:$ts1-1;
  border:1px solid $ts1-3;
  padding:32px;
  border-radius: 16px;
  >.section-resources {
    display: flex;
    flex-direction: column;

    .title {
      display: flex;
      margin-bottom: 16px;
      p {
        @extend %font-m;
        color: $ts10;
      }
    }
    .resources {
      display: flex;
      justify-content:space-between;
      flex-wrap: wrap;
      .item {
        position: relative;
        width: 296px;
        margin-bottom: 28px;

        &:nth-child(4n) {
          margin-right: 0px;
        }

        > div:nth-child(1) {
          height: 148px;
          background: $ts3;
          background-size: cover;
          border-top-left-radius: 16px;
          border-top-right-radius: 16px;
          border:1px solid $ts1-3;
        }

        > div:nth-child(2) {
          position: relative;
          padding: 16px;
          background: $ts1-3;
          min-height: 138px;
          border-bottom-left-radius: 16px;
          border-bottom-right-radius: 16px;
          > div:nth-child(1) {
            margin-bottom: 8px;
            > p {
              @extend %font-m;
              color: $ts10;
              text-align: center;
            }
          }

          > div:nth-child(2) {
            margin-bottom: 12px;

            > p {
              color: $ts10;
              @extend %font-s;
              text-align: center;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2; /* number of lines to show */
              -webkit-box-orient: vertical;
            }
          }

          > div:nth-child(3) {
            position: absolute;
            bottom:16px;
            padding:6px 0px;
            background:$primary;
            width:264px;
            border-radius: 4px;
            text-align: center;
            > p {
              @extend %font-s;
              color: $ts10;
              cursor: pointer;
              > img {
                filter:brightness(100%);
                margin-bottom: 4px;
              }
            }
          }
        }
      }
    }
    &:first-child {
      margin-top: 0;
    }
  }
}

@include screenMaxWidth(600px){
  .marketing-container > .section-resources .resources .item > div:nth-child(2) > div:nth-child(3) {
    position: relative;
    width:100%;
    bottom: 0;
  }
  .box-marketing{
    .marketing-container{
      padding:16px;
      >.section-resources{
        >.resources{
          >.item{
            width: 100%;
          }
        }
      }
    }
  }

}
@media screen and (max-width: $breakpoint-width-xl) {
  .section-resources {
    .box-marketing {
      justify-content: center;
      margin-right: -24px;

      .item {
        margin-right: 24px;

        &.item:nth-child(4n) {
          margin-right: 24px;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-width-xs) {
  .section-resources {
    .box-marketing {
      justify-content: center;
      margin-right: 0px;

      .item {
        width: 100%;
        margin-right: 0px;

        &.item:nth-child(4n) {
          margin-right: 0px;
        }

        > div:nth-child(2) {
          > div:nth-child(1) {
            height: auto;
          }
        }
      }
    }
  }
}
