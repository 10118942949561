.btn-small {
  padding: 0px 12px;
  height: 24px;
  border-radius: 1px;
  @extend %font-xs;
}

.btn-medium {
  padding: 0px 16px;
  height: 32px;
  border-radius: 4px;
  @extend %font-s;
}

.btn-large {
  padding: 0px 16px;
  height: 44px;
  border-radius: 8px;
  @extend %font-m;
}

.btn-success {
  background: $success;
  color: $ts10;
}

.btn-primary {
  background: $primary;
  color: $ts10;
}

.btn-info {
  background: $info;
  color: $ts10;
}

.btn-danger {
  background: $danger;
  color: $ts10;
}

.btn-disable {
  background: $ts3;
  color: $ts10;
  cursor: inherit;
  cursor:not-allowed;
}

.btn-warning {
  background: $warning;
  color: $ts10;
}

.btn-opaque {
  background: $ts3;
  color: $ts10;
}
