//marketplace
.box-home{
  display: flex;
  flex-direction: column;
  background:$ts1-1;
  padding:48px;
  border:1px solid $ts2;
  border-bottom: 0px;
  min-height: 85vh;
  padding-bottom: 106px;
  border-radius: 16px 16px 0px 0px;
  >.products-container{
    margin-bottom: 56px;
    >.heading{
      margin-bottom: 24px;
      >p:nth-of-type(1){
        @extend %font-xxl;
        color:$ts10;
      }
      >p:nth-of-type(2){
        @extend %font-l;
        color:$ts3;
      }
    }
    >.cards-products{
      display: flex;
      justify-content: space-between;
      >.card{
        display: flex;
        flex-direction: column;
        align-items: center;
        background:$ts21;
        padding:24px;
        border:1px solid $ts2;
        border-radius:16px;
        width:100%;
        margin-right: 24px;
        >img{
          width:120px;
          height: 120px;
          margin-bottom: 24px;
        }
        >p{
          @extend %font-l;
          color:$ts10;
          text-transform: uppercase;
          margin-bottom: 16px;
        }
        >.profit{
          display:flex;
          flex-direction: column;
          align-items: center;
          padding:24px;
          width:100%;
          background: $ts2;
          border-radius: 8px;
          margin-bottom: 24px;
          >p{
            @extend %font-m;
            color:$success;
            margin-bottom: 8px;
          }
          >div{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding:8px 0px;
            width:100%;
            background: $ts1;
            border-radius: 8px;
            >p:nth-of-type(1){
              @extend %font-xxs;
              color:$ts3;
            }
            >p:nth-of-type(2){
              @extend %font-l-bold;
              color:$ts10;
            }
          }
        }
        >.button-action{
          display: flex;
          flex-direction: row;
          width: 100%;
          >button{
            width:100%;
            color:$ts1;
          }
        }
        &:nth-of-child(3n+3){
          margin-right: 0px;
        }
        &:last-child{
          margin-right: 0px;
        }
      }
    }
  }
  >.statistic-container{
    >.heading{
      margin-bottom: 24px;
      >p:nth-of-type(1){
        @extend %font-xxl;
        color:$ts10;
      }
      >p:nth-of-type(2){
        @extend %font-l;
        color:$ts3;
      }
    }
    >.inkryptus-statistic{
      display: flex;
      margin-bottom: 24px;
      >div{
        display:flex;
        flex-direction: column;
        align-items: center;
        padding:16px;
        width:100%;
        margin-right: 8px;
        background:$ts1-2;
        border-radius: 8px;
        >p:nth-of-type(1){
          @extend %font-s-bold;
          color:$ts3;
        }
        >p:nth-of-type(2){
          @extend %font-l-bold;
          color:$ts10;
        }
        &:last-child {
          margin-right: 0px;
        }
      }
    }
    >.user-statistic{
      display: flex;
      flex-direction: column;
      background:$ts1-2;
      padding:24px;
      border-radius: 8px;
      >p{
        @extend %font-l-bold;
        color:$ts10;
        margin-bottom: 8px;
      }
      >div{
        display: flex;
        >div{
          display:flex;
          flex-direction: column;
          align-items: center;
          padding:16px;
          width:100%;
          margin-right: 8px;
          background:$ts1;
          border-radius: 8px;
          >p:nth-of-type(1){
            @extend %font-s-bold;
            color:$ts3;
          }
          >p:nth-of-type(2){
            @extend %font-l-bold;
            color:$ts10;
          }
          &:last-child {
            margin-right: 0px;
          }
        }
      }
    }
  }
}
//media marketplace

@include screenMaxWidth(890px){
.box-home{
  >.products-container{
    >.cards-products{
      flex-direction: column;
      >.card{
        margin-bottom: 16px;
        margin-right: 0px;
        &:last-child{
          margin-bottom: 0px;
        }
      }
    }
  }
  >.statistic-container{
    >.inkryptus-statistic{
      flex-direction: column;
      >div{
        margin-bottom: 16px;
        &:last-child{
          margin-bottom: 0px;
        }
      }
    }
    >.user-statistic{
      >div{
        flex-direction: column;
        >div{
          margin-bottom: 16px;
          &:last-child{
            margin-bottom: 0px;
          }
        }
      }
    }
  }
}
}

@include screenMaxWidth(460px){
  .box-home{
    padding:16px;
    padding-bottom: 124px;
    >.products-container{
      >.heading{
        >p:nth-of-type(1){
          font-size: 38px;
        }
        >p:nth-of-type(2){
          font-size: 22px;
        }
      }
    }
  }
}


