.box-network {
  display: flex;
  .information-network {
    width: 100%;
    height: 56px;
    position: relative;
    margin-bottom: 20px;
    display: flex;
    background: $ts1;

    .status {
      height: 100%;
      width: 88px;
      background: $danger;

      > div {
        @extend %align-vertical;

        p {
          @extend %font-s-bold;
          text-transform: uppercase;
          color: $ts10;
          text-align: center;
          margin-bottom: 2px;

          &:last-child {
            @include color-opacity($ts1, 0.6);
            margin-bottom: 0px;
          }
        }
      }
    }

    .item {
      color: $ts10;

      > div {
        margin-left: 20px;
        @extend %align-vertical;

        > p:nth-child(1) {
          @extend %font-xxs-bold;
          margin-bottom: 4px;
        }

        > p:nth-child(2) {
          @extend %font-xxs;
        }
      }
    }

    .profit {
      color: $ts10;
      position: absolute;
      right: 20px;
      height: 100%;

      > div {
        margin-left: 20px;
        @extend %align-vertical;

        > p:nth-child(1) {
          @extend %font-xs-bold;
        }

        > p:nth-child(2) {
          @extend %font-xs;

          &.spotlight {
            color: $success;
          }
        }
      }
    }

    &.active {
      .status {
        background: $success;
      }

      .item {
        > div {
          > p:nth-child(2) {
            @extend %font-xxs;

            &.spotlight {
              color: $success;
            }
          }
        }
      }
    }
  }
  .referral-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    >div:nth-of-type(1){
      display: flex;
      flex-direction: column;
      width:49%;
      >.referral{
        display: flex;
        flex-direction: column;
        padding:32px;
        background:$ts1-1;
        border:1px solid $ts1-3;
        border-radius: 16px;
        margin-bottom: 32px;
        >p:nth-of-type(1){
          @extend %font-m;
          color:$ts10;
          margin-bottom: 16px;
        }
        >.link-referral {
          position: relative;
          display: flex;
          flex-direction: column;
          margin-bottom: 24px;

          > div:nth-child(1) {
            > p {
              @extend %font-s;
              color: $ts10;
              margin-bottom: 4px;
            }
          }

          > div:nth-child(2) {
            margin-bottom: 12px;
            >input{
              border-radius: 4px;
            }
          }

          > div:nth-child(3) {
            > button {
              width: 132px;
              &:first-child {
                margin-right: 12px;
              }
            }
          }

          > div:nth-child(4) {
            visibility: collapse;
            position: absolute;
            top: -32px;
            right: 12px;
            background: $ts2;
            padding: 4px 10px;
            border-radius: 1px;
            border: 1px solid $success;
            transition: 0.5s;
            opacity: 0;

            p {
              @extend %font-xxs;
              color: $ts10;
            }
          }

          &.showing-copy-tooltip {
            > div:nth-child(4) {
              opacity: 1;
              visibility: visible;
            }
          }
        }
        >.email-referral {
          display: flex;
          flex-direction: column;

          > div:nth-child(1) {
            > p {
              @extend %font-s;
              color: $ts10;
              margin-bottom: 4px;
            }
          }

          > div:nth-child(2) {
            margin-bottom: 12px;
            >input{
              background:$ts1-3;
              color:$ts10;
              border-radius: 4px;
              &::placeholder{
                color:$ts3;
              }
            }
          }

          > div:nth-child(3) {
            margin-bottom: 12px;
            >input{
              background:$ts1-3;
              color:$ts10;
              border-radius: 4px;
              &::placeholder{
                color:$ts3;
              }
            }
          }
        }

      }
      >.products-active{
        display: flex;
        flex-direction: column;
        padding:32px;
        background:$ts1-1;
        border:1px solid $ts1-3;
        border-radius: 16px;
        >p{
          @extend %font-m;
          color:$ts10;
          margin-bottom: 8px;
        }
        >.product{
          display:flex;
          background: $ts20;
          padding:12px;
          border-radius: 8px;
          border:1px solid $ts2;
          margin-bottom: 8px;
          >div:nth-of-type(1){
            display:flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width:82px;
            >img{
              height:24px;
              width:24px;
              margin-bottom: 8px;
            }
            >p{
              @extend %font-xxs;
              color:$ts10;
            }
          }
          >div:nth-of-type(2),div:nth-of-type(3),div:nth-of-type(4){
            display:flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background:$ts1-2;
            padding:8px;
            border-radius: 8px;
            width:60px;
            >p{
              @extend %font-xxs;
              color:$ts10;
              &:first-child{
                color:$ts4;
              }
            }
          }
          >div:nth-of-type(2){
            margin-right: 4px;
          }
          >div:nth-of-type(3){
            margin-right: 4px;
          }
          >div:nth-of-type(4){
            flex-grow: 1;
          }
          &:last-child{
            margin-bottom: 0px;
          }
        }
      }
    }
    >.history-referral {
      display: flex;
      flex-direction: column;
      width:49%;
      padding:32px;
      background:$ts1-1;
      border:1px solid $ts1-3;
      border-radius: 16px;
      >.chart-referral {
        margin-bottom: 24px;
        > div:nth-of-type(1) {
          p {
            @extend %font-m;
            color: $ts10;
          }
        }
        > div:nth-of-type(2) {
          height: 236px;
          position: relative;

          > div {
            position: absolute !important;
            bottom: 0px;
            width: 100% !important;

            > svg {
              width: 100% !important;

              > .recharts-cartesian-grid {
                line {
                  stroke: $ts2;
                }
              }

              .recharts-layer {
                > .recharts-bar-rectangle {
                  > path {
                    fill: $primary !important;
                    transition: 0.5s;

                    &:hover {
                      fill: #00ffff !important;
                    }
                  }
                }
              }

              > .yAxis,
              .xAxis {
                > line {
                  stroke: $ts3 !important;
                }

                > g {
                  > g {
                    > line {
                      stroke: $ts3 !important;
                    }

                    > text {
                      fill: $ts3 !important;

                      > tspan {
                        font-weight: 300;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        }
      >.last-registers {
          display: flex;
          flex-direction: column;

          > div:nth-child(1) {
            @extend %font-m;
            color: $ts10;
            margin-bottom: 24px;
          }

          > div:nth-child(2) {
            > table {
              tr {
                border-bottom: 1px solid $ts2;
                width: 100%;

                th {
                  text-align: left;
                  color: $ts3;
                  @extend %font-s-bold;
                  padding: 8px 0px;

                  &:nth-child(1) {
                    width: 252px;
                    padding-left: 12px;
                  }

                  &:nth-child(2) {
                    width: 234px;
                  }

                  &:nth-child(3) {
                    width: 102px;
                  }
                }

                td {
                  text-align: left;
                  color: $ts10;
                  @extend %font-xs;
                  padding: 12px 0px;

                  &:nth-child(1) {
                    padding-left: 12px;
                  }

                  > span {
                    &.active {
                      color: $success;
                    }

                    &.inactive {
                      color: $warning;
                    }
                  }
                }
              }
            }
          }
        }
      }


  }
  .empty-referrals {
    height: 376px;
    width: 100%;
    background: $ts1;
    > div {
      display: flex;
      flex-direction: column;
      width: 220px;
      height: auto;
      @extend %align-center;
      > div:nth-of-type(1) {
        margin-bottom: 40px;
        img {
          @extend %align-horizontal;
        }
      }
      > div:nth-of-type(2) {
        p {
          @extend %font-m;
          color: $ts10;
          text-align: center;
        }
      }
    }
  }
}

.box-network-graph {
  .graph {
    display: flex;
    flex-direction: column;
    position: relative;
    //infobar graph
    > div:nth-of-type(1) {
      display: flex;
      justify-content: space-between;
      padding:16px;
      background: $ts20;
      border:1px solid $ts2;
      border-radius: 8px;
      margin-bottom: 8px;

      //left
      > div:nth-of-type(1) {
        padding-left: 20px;
        display: flex;
        align-items: center;

        p {
          @extend %font-m;
          color: $ts10;
        }

        > div {
          span {
            @extend %font-xxs;
            color: $ts10;
            background: $ts2;
            padding: 1px 18px;
            border-radius: 20px;
            border: 1px solid $primary;
            margin-left: 12px;
          }
        }
      }

      //right
      > div:nth-of-type(2) {
        display: flex;

        > div {
          background:$ts1-2;
          height: 100%;
          width: 64px;
          border-radius: 8px;
          padding: 12px 0px;
          margin-left: 4px;
          &:last-child{
            width: 160px;
          }
          > p {
            text-align: center;


            &:first-child {
              @extend %font-xxs;
              color: $ts4;
              margin-bottom: 4px;
            }

            &:last-child {
              @extend %font-s;
              color: $ts10;
            }
          }
        }
      }
    }
    //graph network
    > div:nth-of-type(2) {
      height: 520px;
      overflow: hidden;
      background: $ts20;
      border:1px solid $ts2;
      border-radius: 8px;

      .nodeCircle {
        stroke: transparent !important;
      }

      .nodeText {
        font-weight: 300 !important;
        transform: translate(0%, -22px) !important;
        text-anchor: middle !important;
      }

      .link {
        stroke: rgba(119, 119, 119, 0.35) !important;
      }
    }
    .open-fullscreen{
      display: flex;
      position: absolute;
      @include background-opacity($ts3, 0.3);
      border:1px solid;
      @include border-opacity($ts10, 0.1);
      border-radius: 4px;
      top:76px;
      right: 12px;
      padding:6px 8px;
      cursor:pointer;
      p{
        @extend %font-s;
        color:$ts10;
        margin-right: 6px;
      }
    }
    .close-fullscreen{
      display: none;
    }
    &.fullscreen{
      display:block;
      position: fixed;
      z-index:1000;
      left:0;
      top:0;
      width: 100%;
      height: 100vh;
      > div:nth-of-type(2){
        height: 100%;
        > svg{
          width: 100%;
          height: 100%;
        }
      }
      .open-fullscreen{
        display: none;
      }
      .close-fullscreen{
        position: absolute;
        @extend %align-horizontal;
        bottom:20px;
        display:block;
        cursor:pointer;
        svg{
          @extend %align-horizontal;
          margin-bottom: $margin-orange;
        }
        p{
          @extend %font-s;
          color:$ts10;
        }
      }
    }
  }
}

@include screenMaxWidth(950px){
  .box-network{
    >.referral-container{
      flex-direction: column;
      >div:nth-of-type(1){
        width: 100%;
        margin-bottom: 16px;
        >.referral{
          margin-bottom: 16px;
        }
      }
      >.history-referral{
        width: 100%;
      }
    }
  }
}
@include screenMaxWidth(500px){
  .box-network{
    >.referral-container{
      >div:nth-of-type(1){
        >.referral{
          padding:16px;
          >.link-referral {
            >div:nth-of-type(3){
              display:flex;
              flex-direction: column;
              >button{
                width: 100%;
                &:first-child{
                  margin-bottom: 8px;
                }
              }
            }
          }
        }
        >.products-active{
          padding:16px;
          >.product{
            flex-direction: column;
            align-items:center;
            >div:nth-of-type(2),div:nth-of-type(3),div:nth-of-type(4){
              width: 100%;
              margin-bottom: 8px;
              margin-right: 0px;
            }
          }
        }
      }
    }
  }
}
