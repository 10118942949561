@keyframes timer-payment-opacity {
  0% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
.timer-running {
  animation: timer-payment-opacity 1s infinite;
}
.leading-zeroes {
  color: rgba(255, 255, 255, 0.3) !important;
}
