.box-profile {
  display: flex;
  flex-direction: column;
  >.data-container{
    display: flex;
    justify-content:space-between;
    padding:40px 36px;
    background:$ts1-2;
    border-radius: 8px;
    >.picture {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 49%;
      background:$ts2;
      border-radius: 16px;

      > div:nth-child(1) {
        margin-bottom: 24px;
        > div:nth-of-type(1) {
          width: 200px;
          @extend %align-horizontal;
          > img {
            width: 160px;
            height: 160px;
            border-radius: 80px;
            cursor: pointer;
            @extend %align-horizontal;
          }
          > div {
            width: 160px;
            height: 160px;
            border-radius: 50%;
            cursor: pointer;
            @extend %align-horizontal;
            @include background-opacity($ts1, 0.75);
            border: 3px solid $warning;
            position: absolute;
            z-index: 2;
            visibility: collapse;
            opacity: 0;
            transition: 0.5s;
            > div {
              @extend %align-vertical;
              img {
                @extend %align-horizontal;
                margin-bottom: 4px;
              }
              p {
                @extend %font-xs;
                color: $ts10;
                text-align: center;
                text-decoration: underline;
              }
            }
          }
          &:hover {
            > div {
              visibility: visible;
              opacity: 1;
            }
          }
        }
      }
      > div:nth-child(2) {
        > p {
          text-align: center;
          @extend %font-l;
          color: $ts10;
        }
      }
    }

    >.data-user {
      display: flex;
      flex-direction: column;
      width: 49%;
      > div {
        margin-bottom: 12px;
        display: flex;
        flex-direction: column;

        > div:nth-child(1) {
          width: 180px;

          > p {
            @extend %font-s;
            color: $ts10;
            line-height: 18px;
            margin-bottom: 2px;
          }
        }

        > div:nth-child(2) {
          width: 420px;
          height: auto;

          > input,
          select {
            height: 36px;
            margin-bottom: 8px;
            @include color-opacity($ts10, 0.5);
            @extend %font-sm;
            appearance: none;
            border-radius: 8px;
            &::placeholder {
              @include color-opacity($ts10, 0.5);
            }
          }

          > button {
            width: 100%;
          }

          > img {
            cursor: pointer;
            top: 18px;
          }
        }

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }


}

.box-change-password {
  .title {
    display: flex;
    flex-direction: column;

    > p:nth-child(1) {
      @extend %font-l;
      color: $ts10;
      text-align: center;
      margin-bottom: 34px;
    }

    > p:nth-child(2) {
      @extend %font-s;
      @extend %align-horizontal;
      width: 600px;
      color: $ts10;
      text-align: left;
      margin-bottom: 24px;

      > img {
        margin-right: 5px;
        margin-bottom: 4px;
      }
    }
  }

  .info-password {
    width: 600px;
    @extend %align-horizontal;

    > div {
      margin-bottom: 12px;
      display: flex;

      > div:nth-child(1) {
        width: 180px;

        > p {
          @extend %font-m;
          color: $ts3;
          line-height: 37px;
        }
      }

      > div:nth-child(2) {
        width: 420px;

        > input {
          @include color-opacity($ts10, 0.5);
          @extend %font-m;

          &::placeholder {
            @include color-opacity($ts10, 0.5);
          }
        }

        > img {
          cursor: pointer;
        }
      }

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}

@include screenMaxWidth(1000px){
  .box-profile{
    >.data-container{
      flex-direction: column;
      >.picture{
        width: 100%;
        padding:32px;
        flex-direction: row;
        margin-bottom: 16px;
        >div:nth-of-type(1){
          margin-bottom: 0px;
        }
      }
      >.data-user{
        width: 100%;
        >div{
          >div:nth-of-type(2){
            width: 100%;
          }
        }
      }
    }
  }
}
@include screenMaxWidth(600px){
  .box-profile{
    >.data-container{
      padding:24px;
      >.picture{
        flex-direction: column;
      }
    }
  }
}
//media box profile
@media screen and (max-width: $breakpoint-width-m) {
  .box-profile {
    .info-user {
      width: 100%;
    }

    .wallet-acw {
      width: 100%;

      > div:nth-child(2) {
        > div:nth-child(2) {
          height: 100%;
          width: 100%;

          > div:nth-child(2) {
            > div:nth-child(2) {
              flex-direction: column;

              > div:nth-of-type(1) {
                width: 100%;
                margin-bottom: 8px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-width-xs) {
  .box-profile {
    .info-user {
      width: 100%;

      > div {
        flex-direction: column;

        > div:nth-child(2) {
          width: 100%;
        }
      }
    }

    .wallet-acw {
      > div:nth-child(2) {
        flex-direction: column;

        > div:nth-of-type(1) {
          width: 100%;
          margin-bottom: 20px;
          border-bottom: 2px solid $ts10;
        }
      }
    }
  }
}

//media box change password
@media screen and (max-width: $breakpoint-width-m) {
  .box-change-password {
    .title {
      > p:nth-child(2) {
        width: 100%;
      }
    }

    .info-password {
      width: 100%;
    }
  }
}

@media screen and (max-width: $breakpoint-width-xs) {
  .box-change-password {
    .info-password {
      > div {
        flex-direction: column;

        > div:nth-child(1) {
          width: 100%;
        }

        > div:nth-child(2) {
          width: 100%;
        }
      }
    }
  }
}
