@mixin screenMaxWidth ($width){
  @media screen and (max-width: $width){
      @content;
  }
}

@mixin screenMinWidth ($width){
  @media screen and (min-width: $width){
      @content;
  }
}

@mixin screenMinHeight ($height){
      @media screen and (min-height: $height){
          @content;
      }
}

@mixin screenMaxHeight ($height){
  @media screen and (max-height: $height){
      @content;
  }
}
