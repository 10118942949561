.box-swap-symbol-selector{
    display: flex;
    flex-direction: column;
    width: 440px;
    background:$ts2;
    border-radius: 16px;
    padding:24px;
    @extend %align-center;
    >.title{
      margin-bottom: 24px;
      >p{
        @extend %font-l;
        color:$ts10;
      }
    }
    >.input-box{
      margin-bottom: 24px;
      >input{
        width: 100%;
        @include background-opacity($ts1, 0.5);
        border:1px solid $ts2;
        border-radius: 8px;
        @extend %font-s;
        color:$ts10;
        padding:12px 14px;
        &::placeholder{
          color:$ts3;
        }
      }
    }
    >.coins{
      display: flex;
      flex-direction: column;
      >div{
        display: flex;
        margin-bottom: 16px;
        >img{
          width: 24px;
          height: 24px;
          margin-right: 8px;
          cursor: pointer;
        }
        >p{
          @extend %font-xs;
          color:$ts10;
          line-height: 24px;
          cursor: pointer;
        }
        &:last-child{
          margin-bottom: 0px;
        }
      }
    }
    @media screen and (max-width: $breakpoint-width-s){
      width: 100%;
      transform: translate(-50%, 0%);
      top:0px;
    }
 }

