.section-resources {
  display: flex;
  flex-direction: column;
  margin-top: $margin-red;
  .title {
    display: flex;
    margin-bottom: $margin-red;
    p {
      @extend %font-l;
      color: $ts10;
      margin-right: $margin-red;
      margin-top: 2px;
    }
  }
  .box-tutorials {
    display: flex;
    flex-wrap: wrap;

    .item {
      width: 236px;
      margin-right: 24px;
      margin-bottom: 28px;
      position: relative;
      &:nth-child(4n) {
        margin-right: 0px;
      }

      > div:nth-child(1) {
        height: 148px;
        background-size: cover !important;
        background-position: center;
        > div {
          display: flex;
          flex-direction: column;
          width: 130px;
          @extend %align-center;
          cursor: pointer;
          background-size: cover;

          > img {
            width: 40px;
            height: 40px;
            margin-bottom: 12px;
            @extend %align-horizontal;
          }

          > p {
            @extend %font-xs-bold;
            color: $ts10;
            text-align: center;
          }
        }
      }

      > div:nth-child(2) {
        padding: 12px 16px;
        background: $ts1;
        min-height: 220px;

        > div:nth-child(1) {
          margin-bottom: 12px;

          > p {
            @extend %font-m-bold;
            color: $ts10;
            line-height: 22px;
          }
        }

        > div:nth-child(2) {
          margin-bottom: 12px;
          min-height: 80px;
          > p {
            color: $ts10;
            @extend %font-s;
            line-height: 20px;
          }
        }
        > div:nth-child(3) {
          position: absolute;
          bottom: 12px;
          > p {
            cursor: pointer;
            color: $primary;

            > img {
              margin-bottom: 4px;
            }
          }
        }
      }
    }
  }
  &:first-child {
    margin-top: 0;
  }
}

@media screen and (max-width: $breakpoint-width-xl) {
  .section-resources {
    .box-tutorials {
      justify-content: center;
      margin-right: -24px;

      .item {
        margin-right: 24px;

        &.item:nth-child(4n) {
          margin-right: 24px;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-width-xs) {
  .section-resources {
    .box-tutorials {
      justify-content: center;
      margin-right: 0px;

      .item {
        width: 100%;
        margin-right: 0px;

        &.item:nth-child(4n) {
          margin-right: 0px;
        }

        > div:nth-child(2) {
          > div:nth-child(1) {
            height: auto;
          }
        }
      }
    }
  }
}
