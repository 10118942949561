//marketplace
.box-products-overview{
  display: flex;
  flex-direction: column;
  padding-bottom: 106px;
  >.product{
    display:flex;
    align-items: center;
    background:$ts20;
    border:1px solid $ts1-2;
    border-radius:8px;
    margin-bottom: 16px;
    padding:12px 16px;
    >.logo{
      margin-right:24px;
      width: 36px;
      height: 36px;
    }
    >.description{
      display: flex;
      flex-direction: column;
      max-width: 390px;
      >p:nth-of-type(1){
        @extend %font-l;
        color:$ts10;
        text-align: left;
      }
      >div{
        display: none;
        padding:8px 16px;
        background:$ts2;
        border-radius: 16px;
        p{
          @extend %font-m;
          color:$success;
        }

      }
    }
    >.profit{
      display: flex;
      flex-direction: row;
      margin-left: auto;
      margin-right: 36px;
      >div{
        display: flex;
        flex-direction: column;
        background:$ts2;
        padding:4px 24px;
        border-radius: 8px;
        margin-right: 8px;
        p{
          text-align: center;
        }
        >p:nth-of-type(1){
          @extend %font-xxs;
          color:$ts3;
        }
        >p:nth-of-type(2){
          @extend %font-m-bold;
          color:$ts10;
        }
        &:last-child {
          margin-right: 0px;
        }
      }
    }
    >.join-now{
      display: flex;
      button{
        width:150px;
        color:$ts10;
      }
    }
  }
}
//media marketplace
@include screenMaxWidth(940px){
  .box-products-overview{
    padding:16px 16px 100px 16px;
    >.product{
      flex-direction: column;
      >.logo{
        margin-right: 0px;
      }
      >.description{
        align-items: center;
        margin-bottom: 16px;
      }
      >.profit{
        margin: 0px;
        margin-bottom: 16px;
      }
    }
  }
}
@include screenMaxWidth(500px){
  .box-products-overview{
    >p:nth-of-type(1){
      margin-bottom: 16px;
      font-size: 32px;
    }
    >.product{
      >.profit{
        >div{
          >p:nth-of-type(2){
            margin-top: auto;
          }
        }
      }
    }
  }
}
@media screen and (max-width: $breakpoint-width-xl) {

  .box-contract{
    >.contract{
      >.avarage-profit{
        flex-shrink: 0;
        max-height: 284px;
      }
    }
    >.my-contracts{
      >.contract{
        width:auto;
        overflow-x: auto;
        >div{
          flex-shrink: 0;
        }
        >.profit-liquid{
          margin-right: 0px;
        }
        >.button-action{
          min-width: 164px;
        }
      }
    }
  }
}

