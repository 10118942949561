.box-contracts-overview{
  >.table{
    >.table-container{
      overflow-x: auto;
      >table{
        width: 100%;
        color:$ts10;
        >thead{
          text-align: left;
          @extend %font-xxs-bold;
          color:$ts3;
          border-bottom: 1px solid $ts1-3;
          >tr{
            >th{
              cursor:pointer;
              padding-bottom: 8px;
              >img{
                margin-top: -2px;
              }
            }
          }
        }
        >tbody{
          >tr{
            border-bottom: 1px solid $ts2;
            &:last-child{
              border-bottom: 0px;
            }
            >td{
              padding:8px 0px;
              &:nth-of-type(1){
                >div{
                  display: flex;
                  align-items: center;
                  >img{
                    height: 32px;
                    width: 32px;
                    margin-right: 8px;
                  }
                  >div{
                    >p{
                      @extend %font-xxs;
                      &:nth-child(1){
                        color:$ts10;
                      }
                      &.success{
                        color:$success;
                      }
                      &.danger{
                        color:$danger
                      }
                      &.warning{
                        color:$warning;
                      }
                    }
                  }
                }
              }
              &:nth-of-type(2){
                vertical-align: middle;
                >div{
                  display: flex;
                  flex-direction: column;
                  >p{
                    @extend %font-xxs;
                  }
                }
              }
              &:nth-of-type(3){
                vertical-align: middle;
                >p{
                  @extend %font-xxs;
                }
              }
              &:nth-of-type(4){
                vertical-align: middle;
                >p{
                  @extend %font-xxs;
                }
              }
              &:nth-of-type(5){
                vertical-align: middle;
                >div{
                  display: flex;
                  flex-direction: column;
                  >p{
                    @extend %font-xxs;
                  }
                }
              }
              &:nth-of-type(6){
                vertical-align: middle;
                >p{
                  @extend %font-xxs;
                  color:$success;
                }
              }
              &:nth-of-type(7){
                vertical-align: middle;
                >div{
                  display:flex;
                  flex-direction: row-reverse;
                }
              }
            }
          }
        }
      }
    }
  }
}
@include screenMaxWidth(1000px){
  .box-contracts-overview{
    >.table{
      >.table-container{
        >table{
          min-width: 934px;
        }
      }
    }
  }
}
@include screenMaxWidth(560px){
  .box-contracts-overview{
    >.table{
      >.options{
        flex-direction: column;
        >.left-side {
          margin-bottom: 16px;
          >.select-buttons{
            >div{
              padding:12px;
            }
          }
        }
      }
    }
  }
}
