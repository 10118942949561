.modal-notification{
  display:flex;
  flex-direction: column;
  justify-content:center;
  align-items: center;
  padding:38px;
  width:464px;
  @include background-opacity($ts2, 0.8);
  border:1px solid;
  @include border-opacity($ts3, 0.3);
  border-radius: 4px;
  @extend %align-horizontal;
  >img{
    width:88px;
    object-fit:fill;
    margin-bottom: 8px;
  }
  >p{
    @extend %font-l;
    color:$ts10;
    text-align: center;
    margin-bottom: 20px;
  }
  >button{
    width:100%;
    max-width: 120px;
  }
}

@media screen and (max-width: $breakpoint-width-xs){
  .modal-notification{
    width:100%;
  }
}
