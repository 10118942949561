.box-financial-wallet {
  >.wallet{
    display: flex;
    justify-content: space-between;
    background:$ts1-2;
    border-radius: 8px;
    padding:18px;
    margin-bottom: 20px;
    &.has-harvest-locked {
      margin-bottom: 0px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    &.is-harvest-locked {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    >div{
      display: flex;
      >img,.img-replacement{
        height: 34px;
        width: 34px;
        object-fit: fill;
        margin-right: 28px;
      }
      >.text-content{
        display: flex;
        >div{
          display: flex;
          width:142px;
          position: relative;
          flex-direction: column;
          justify-content:center;
          margin-right: 20px;
          >p:nth-child(1){
            @extend %font-xs;
            color:$ts3;
          }
          >p:nth-child(2){
            @extend %font-m;
            color:$ts10;
          }
        }
        >button{
          @extend %align-vertical;
          margin-right: 40px;
          min-width: 140px;
        }

      }
      >.button-action{
        >button{
          @extend %align-vertical;
        }
      }
    }
    >p{
      display: none;
    }
  }
  >.table{
    >.table-container{
      overflow-x: auto;
      >table{
        width: 100%;
        color:$ts10;
        >thead{
          text-align: left;
          @extend %font-xxs-bold;
          color:$ts3;
          border-bottom: 1px solid $ts1-3;
          >tr{
            >th{
              cursor:pointer;
              padding-bottom: 8px;
              >img{
                margin-top: -2px;
              }
            }
          }
        }
        >tbody{
          >tr{
            border-bottom: 1px solid $ts2;
            &:last-child{
              border-bottom: 0px;
            }
            >td{
              padding:8px 0px;
              &:nth-of-type(1){
                >img{
                  height:16px;
                  width:16px;
                }
              }
              &:nth-of-type(2){
                >p,span{
                  @extend %font-s;
                  color:$ts10;
                }
                >a{
                  @extend %font-s;
                  color:$primary;
                }
              }
              &:nth-of-type(3){
                >p{
                  @extend %font-s;
                  color:$ts10;
                }
              }
              &:nth-of-type(4){
                >p{
                  @extend %font-s;
                  color:$ts10;
                }
              }
              &:nth-of-type(5){
                >p{
                  @extend %font-s;
                  color:$ts10;
                  &.success{
                    color: $success;
                  }
                  &.warning{
                    color:$warning;
                  }
                  &.danger{
                    color:$danger;
                  }
                }
              }
              &:nth-of-type(n+6):nth-of-type(-n+9){
                >p,>span{
                  @extend %font-s;
                  color:$ts10;
                }
              }
            }
          }
        }
      }
    }
  }
  .history-transactions {
    height: auto;
    width: 100%;

    > div:nth-child(1) {
      background: $ts1;
      padding: 12px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $ts2;

      .title {
        p {
          @extend %font-l;
          color: $ts10;
        }
      }

      .filter {
        display: flex;

        > div:nth-child(1) {
          width: 152px;
          margin-right: 12px;
        }

        > div:nth-child(2) {
          display: flex;
          margin-right: 8px;

          > p {
            color: $ts10;
            @extend %font-s;
            line-height: 27px;
            margin-right: 4px;
          }

          input {
            &::-webkit-calendar-picker-indicator {
              &:hover {
                background-color: transparent;
                cursor: pointer;
              }
            }

            &::-webkit-clear-button {
              display: none;
            }

            &::-webkit-inner-spin-button {
              display: none;
            }
          }
        }

        > div:nth-child(3) {
          display: flex;

          > p {
            color: $ts10;
            @extend %font-s;
            line-height: 27px;
            margin-right: 4px;
          }

          input {
            &::-webkit-calendar-picker-indicator {
              &:hover {
                background-color: transparent;
                cursor: pointer;
              }
            }

            &::-webkit-clear-button {
              display: none;
            }

            &::-webkit-inner-spin-button {
              display: none;
            }
          }
        }
      }
    }

    > div:nth-child(2) {
      > table {
        background: $ts1;
        width:100%;
        tr {
          border-bottom: 1px solid $ts2;
          width: 100%;

          th {
            text-align: left;
            color: $ts3;
            @extend %font-s-bold;
            padding: 8px 0px;

            &:nth-child(1) {
              padding-left: 12px;
            }

          }

          td {
            text-align: left;
            color: $ts10;
            @extend %font-xs;
            padding: 12px 0px;

            &:nth-child(1) {
              padding-left: 12px;
              padding-right: 30px;
              max-width:140px;
              white-space:nowrap;
              overflow:hidden;
              text-overflow:ellipsis;
            }
            &:nth-child(2){
              max-width: 120px;
            }
            &:nth-child(6) {

              > a {
                @extend %font-link;
              }
            }
            span {
              &.confirmed {
                color: $success;
              }

              &.waiting {
                color: $info;
              }

              &.success {
                color: $success;
              }
              &.danger {
                color: $danger;
              }
            }

            &.adress {
              color: $primary;
              cursor: pointer;

              > img {
                margin-left: 5px;
              }
            }
          }
        }
      }
    }
  }
}

@include screenMaxWidth(1000px){
  .box-financial-wallet{
    >.table{
      >.table-container{
        >table{
          min-width: 934px;
        }
      }
    }
    >.wallet{
      flex-direction: column;
      >div:nth-of-type(1){
        flex-direction: column;
        align-items: center;
        >img{
          margin-right: 0px;
          margin-bottom: 16px;
        }
        >.img-replacement {
          display: none;
        }
        >.text-content{
          flex-direction: column;
          margin-bottom: 16px;
          >div{
            margin-right: 0px;
            align-items: center;
            >p:nth-of-type(1){
              margin-bottom: 0px;
            }
            >p:nth-of-type(2){
              margin-bottom: 8px;
            }
          }
          >button{
            top:0;
            transform:inherit;
            margin-right: 0px;
          }
        }
      }
      >div:nth-of-type(2){
        justify-content: center;
        >.text-content{
          >div{
            margin-right: 0px;
            >p{
              text-align: center;
            }
          }
        }
      }
    }
  }
}
@include screenMaxWidth(560px){
  .box-financial-wallet{
    >.table{
      >.options{
        flex-direction: column;
        >.left-side {
          margin-bottom: 16px;
          >.select-buttons{
            >div{
              padding:12px;
            }
          }
        }
      }
    }
  }
}
//Media comissions e financial debits
@media screen and (max-width: $breakpoint-width-xl) {
  .box-financial-wallet {
    .balance-summary {
      .detail-balance {
        width: 100%;
        margin-bottom: 12px;
        overflow-x: auto;
        overflow-y: none;

        > div {
          width: 800px;
        }

        > div:nth-child(1) {
          > div:first-child {
            min-width: 108px;
          }

          > div:nth-child(n + 2):nth-child(-n + 8) {
            min-width: 92px;
          }

          > div:last-child {
            min-width: 110px;
            position: relative;
          }
        }

        > div:nth-child(2) {
          > div:first-child {
            min-width: 108px;
          }

          > div:nth-child(n + 2):nth-child(-n + 8) {
            min-width: 92px;
          }

          > div:last-child {
            min-width: 110px;
            position: relative;
          }
        }

        > div:nth-child(3) {
          > div:first-child {
            min-width: 108px;
          }

          > div:nth-child(n + 2):nth-child(-n + 8) {
            min-width: 92px;
          }

          > div:last-child {
            min-width: 110px;
            position: relative;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-width-l) {
  .box-financial-wallet {
    .balance-summary {
      .total-balance {
        min-width: 150px;
        height: 100px;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-width-m) {
  .box-financial-wallet {
    >.wallet{
      width: 100%;
      overflow-x: auto;
    }
    >.history-transactions{
      >div:nth-child(2){
        >table{
          tr{
            td:nth-child(1){
              padding-right: 12px;
            }
          }
        }
      }
    }
    .balance-summary {
      .total-balance {
        display: none;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-width-m) {
  .box-financial-wallet {
    .history-transactions {
      overflow-x: auto;

      > div {
        min-width: 800px;
      }
    }
  }
}
