.box-view {
  .menu {
    height: 80px;
    width: 100%;
    border: 1px solid;
    @include border-opacity($ts2, 0.75);
    @include background-opacity($ts1, 0.75);
    display: flex;
    justify-content: space-between;
    //left menu
    > div:nth-child(1) {
      padding: 28px 32px;
      @extend %font-l;
      @include color-opacity($ts10, 0.9);
      img {
        margin-right: 8px;
        margin-bottom: 6px;
      }
    }
    //tabs menu
    > div:nth-child(2) {
      display: flex;
      p {
        @extend %font-m;
        @include color-opacity($ts10, 0.8);
        line-height: 78px;
        margin-right: 20px;
        cursor: pointer;
        transition: border 0.7s;
        border-bottom: 0px solid $ts11;
        &:last-child {
          margin-right: 32px;
        }
        &.active {
          border-bottom: 4px solid $warning;
        }
        &.disabled {
          color: $ts3;
          cursor: not-allowed;
        }
      }
    }
    //tab filter
    .tab-filter {
      display: flex;
      align-items: center;
      padding: $padding-red;
      > div {
        margin-right: $margin-red;
        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }
}
@media screen and (max-width: $breakpoint-width-l) {
  .box-view {
    .menu {
      //left menu
      > div:nth-child(1) {
        padding: 30px 20px;
        font-size: 16px;
        img {
          width: 16px;
        }
      }
      //right menu
      > div:nth-child(2) {
        p {
          font-size: 14px;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-width-s) {
  .box-view {
    .menu {
      height: auto;
      justify-content: unset;
      flex-direction: column;
      border: none;
      > div:nth-child(1) {
        padding: 12px;
        border-bottom: 1px solid $ts2;
      }
      > div:nth-child(2) {
        padding: 0px 12px;
        p {
          line-height: 42px;
          &.active {
            border-bottom: 1px solid $warning;
          }
        }
      }
      > div.tab-filter {
        display: flex;
        flex-wrap: wrap;
        padding: 12px 12px 8px 12px;
        > div {
          margin-bottom: 4px;
        }
      }
    }
  }
}
