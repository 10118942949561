//response signup
.response-signup {
  display: flex;

  > p {
    @extend %font-s;
  }

  .success {
    color: $success;
  }

  .error {
    color: $danger;
  }

  > img {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
}

// response modal toast swal2
.swal2-popup {
  border-radius: 1px !important;
  background: $ts1 !important;
  border: 1px solid $ts2 !important;

  .swal2-header {
    h2 {
      color: $ts10 !important;
    }
  }

  .swal2-content {
    font-family: 'Signika', sans-serif !important;
    @extend %font-m;
    color: $ts10 !important;
  }

  // area de buttons
  .swal2-actions {
    border: none !important;

    .swal2-styled.swal2-confirm {
      height: 28px !important;
      padding: 0px 20px !important;
      background-color: $primary !important;
      border-radius: 1px !important;
      border: none !important;
      width: 150px !important;
      border-color: $ts1 !important;
      font-family: 'Signika', sans-serif !important;
      @extend %font-s;
    }
  }

  .swal2-footer {
    border-top: 1px solid !important;
    @include border-opacity($ts3, 0.5);

    a {
      color: $ts10 !important;
      text-decoration: underline !important;
    }
  }
}
