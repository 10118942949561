//portal
.modal-portal,
.ReactModalPortal {
  position: absolute;
  left: 0;
  top: 0;
}

//remove menu quando modal esta ativo e desabilita o scroll
.ReactModal__Body--open {
  overflow: hidden !important;

  footer {
    display: none;
  }
}

//overlay
.overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  @include background-opacity($ts1, 0.9);
  z-index: 3;
  overflow-y: auto;
}

.fullscreen-network{
  z-index:1001
}

.overlay-transparent {
  position: fixed;
  height: 100%;
  width: 100%;
  background: transparent;
  z-index: 3;
  overflow-y: auto;
}
