.box-invitation-link {
  width: 462px;
  border-radius: 1px;
  padding: $padding-red;
  border: 1px solid;
  @include background-opacity($ts2, 0.95);
  @include border-opacity($ts3, 0.3);
  @extend %align-center;
  .title {
    margin-bottom: $margin-red;
    p {
      @extend %font-l;
      color: $ts10;
      text-align: center;
    }
  }
  .social-media {
    margin-bottom: $margin-red;
    display: flex;
    > div {
      display: flex;
      cursor: pointer;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      min-width: 120px;
      height: 110px;
      @include background-opacity($ts1, 0.5);
      border: 1px solid transparent;
      border-radius: 2px;
      margin-right: 2px;
      > svg {
        margin-bottom: $margin-orange;
      }
      > p {
        @extend %font-xs-bold;
        color: $ts10;
        margin-bottom: $margin-green;
      }
      > a {
        @extend %font-xs;
        @extend %font-link;
      }
      &:hover {
        border: 1px solid $primary;
        transition: 1s;
      }
    }
  }
  .footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

//media modal box-affiliate
@media screen and (max-width: $breakpoint-width-xs) {
  .box-invitation-link {
    width: 96%;
    .social-media {
      flex-wrap: wrap;
      justify-content: center;
      > div {
        margin-bottom: 2px;
      }
    }
  }
}
