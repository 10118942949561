.box-login {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items:center;
  padding:20px;

    .login {
      display: flex;
      flex-direction: column;
      align-items:center;
      background:$ts10;
      padding:30px;
      border-radius: 16px;
      max-width: 400px;
      width:100%;
      overflow-y: auto;
      > div:nth-child(1) {
        margin-bottom: 40px;
        img{
          width:100%;
          max-width: 250px;
        }
      }
      > div:nth-child(2) {
        width:100%;
        display: flex;
        flex-direction: column;
        > p:nth-child(1) {
          @extend %font-l;
          color: $ts1;
          margin-bottom: 28px;
        }
        > div:nth-child(2) {
          margin-bottom: 16px;
          > input {
            color: $ts3;
            &::placeholder {
              color: $ts3;
            }
          }
        }
        > div:nth-child(3) {
          margin-bottom: 20px;
          > input {
            color: $ts3;
            &::placeholder {
              color: $ts3;
            }
          }
        }
        > button:nth-child(4) {
          width: 100%;
          margin-bottom: 14px;
        }
        > a:nth-child(5) {
          cursor: pointer;
          @extend %font-s;
          @extend %font-link;
          text-align: center;
        }
        .response-signup{
          margin-top:12px;
          >.error{
            margin-bottom: 0px;
          }
        }
      }
    }

}

//Media queries

@media screen and (max-height: 450px) {
  .box-login{
    align-items: initial;
    >.login{
      height: auto;
    }
  }
}
